/* Copyright (C) 2025 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

'use strict';

angular
    .module('ppxControllers')
    .controller('AppController',
        function ($scope, $rootScope, $location, $timeout, $routeParams, NavigationService, navigationService, updateService, shortcutService, eventService, modalService, UserService, features, walkthrough, browserService, userService, sdk) {
            $scope.appCtrl = $scope;

            $rootScope.generalfunctions = {
              canSkipAll: window.generalfunctions_canSkipAll
            };

            window.__pageproof_ctrl__ = window.__pageproof_ctrl__ || {};
            window.__pageproof_ctrl__['appCtrl'] = $scope;

            $scope.regex = {
                email: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            };

            $scope.modalService = modalService;

            $scope.isTouch = angular.element(document.documentElement).hasClass('touch');

            $scope.mobile = /Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) &&
                            $scope.isTouch;

            $scope.isMobile = browserService.is('mobile'); // It's a newer version of finding if user is on mobile

            $scope.features = features.flags;
            $scope.walkthrough = walkthrough;
            $scope.hasWebhooksFeature = userService.getUser().features.enableWebhooks;
            $scope.hasChecklistsFeature = userService.getUser().features.enableChecklists;
            $scope.hasProofTemplatesFeature = userService.getUser().features.enableProofTemplates;

            sdk.graphql('' +
                'query teamSettings {' + 
                    'me {' +
                        'permissions {' +  
                            'userGroups {' +
                                'canViewGroups {' +
                                    'value' +
                                '}' +
                            '}' +
                        '}' +
                    '}' +
                '}',
                {},
                { throwOnError: false })
            .then(function(result) {
                $scope.isEnterpriseAccount = result.data.me.permissions.userGroups.canViewGroups.value;
            });


            $scope.$watch(
                function () {
                    return userService.getUser().features;
                },
                function (userFeatures) {
                    $scope.hasChecklistsFeature = userFeatures.enableChecklists;
                    $scope.hasProofTemplatesFeature = userFeatures.enableProofTemplates;
                    $scope.hasWebhooksFeature = userFeatures.enableWebhooks;
                }
            );

            $rootScope.$on('$locationChangeSuccess', walkthrough.onLocationChangeSuccess)

            var headerControls = $rootScope.headerControls = {
                show: true,
                lock: true,
            };

            $rootScope.isHeaderVisible = function () {
                return headerControls.show && !$rootScope.isCreatingComment;
            };

            $scope.$watch($rootScope.isHeaderVisible, function (showHeader) {
                angular.element(document.documentElement)[showHeader ? 'removeClass' : 'addClass']('hide-header');
            });

            $scope.isDarkMode = localStorage.getItem('pageproof.app.dark-mode') === 'true';

            $scope.$watch('isDarkMode', function(isDarkMode) {
                document.documentElement.classList[isDarkMode ? 'add' : 'remove']('is-dark-mode');
                localStorage.setItem('pageproof.app.dark-mode', isDarkMode ? 'true' : 'false');
            });

            $scope.setIsDarkMode = function(isDarkMode) {
                $scope.isDarkMode = isDarkMode;
                if (!$scope.$$phase) {
                    $scope.$apply();
                }
            };

            shortcutService.watch('darkMode', function() {
                $scope.isDarkMode = !$scope.isDarkMode;
            });

            const pointer = { x: 0, y: 0 };
            document.addEventListener('mousemove', function(event) {
                pointer.x = event.pageX;
                pointer.y = event.pageY;

                if (!headerControls.lock) {
                    autoCalculateShowHeader();
                }
            });

            function autoCalculateShowHeader() {
                if (!headerControls.lock) {
                    const before = headerControls.show;
                    headerControls.show = pointer.y <= (headerControls.show ? 360 : 20);

                    if (before !== headerControls.show) {
                        if (!$scope.$$phase) $scope.$apply();
                    }
                }
            }

            $scope.$watch(function () { return headerControls.lock; }, function () {
                autoCalculateShowHeader();
            });

            $scope.getDashPath = function () {
                return UserService.IsLoggedIn() ? '/dashboard/inbox' : '/';
            };

            $scope.gotoDash = function () {
                $location.path(this.getDashPath());
            };

            /**
             *
             */
            //eventService.on(window, 'resize',
            $scope.onResize = function () {
                $scope.pageHeight = angular.element('#main').height();
            }
            //);

            $scope.onResize();

            /**
             * Redirects the user with a backwards animation.
             *
             * @param {String} url
             */
            $scope.back = NavigationService.wrapBack(function (url) {
                if ($routeParams.back || url) {
                    $location.url($routeParams.back || url);
                    return true;
                }
                return false;
            });

            /**
             * Encodes a string so it can be used in a URL.
             *
             * @param {String|*} value
             * @returns {String}
             */
            $scope.encode = function (value) {
                if (typeof value === 'string' && value.length) {
                    return window.encodeURIComponent(value);
                }

                return '';
            };

            /**
             * Scroll to a specific element.
             *
             * @param {String} el
             */
            $scope.scrollTo = function (el) {
                navigationService.scrollTo(el);
            };

            /**
             * Set/unset the intended url.
             *
             * @param {String} [url]
             */
            $scope.intend = function (url) {
                if (angular.isDefined(url)) {
                    navigationService.setIntended(url);
                } else {
                    navigationService.clearIntended();
                }
            };

            /**
             * Refreshes the page.
             */
            $scope.refresh = function () {
                window.location.href = window.location.href;
            };

            /**
             * Concats a string, and appends an ellipsis if it goes over the length limit.
             *
             * @param {String} text
             * @param {Number} length
             * @returns {String}
             */
            $scope.ellipsis = function (text, length) {
                return text.trim().substring(0, length).trim() + (text.length > length ? '...' : '');
            };

            // moment('Friday 4th September 2015 10am', 'dddd Do MMMM YYYY HHA').utc().unix() * 1000
            $scope.od = (moment.utc().unix() * 1000) >= 1441317600000 && env('onedrive_client_id');

            updateService.interval(UserService.GetConfigSetting('UpdateInterval'), function (outdated) {
                $scope.outdated = outdated;
            });

            shortcutService.watch('menu', $scope.toggleMenu = function () {
                var userId = UserService.userData.userId;
                if (userId) {
                    $scope.menuOpen = ! $scope.menuOpen;
                }
            });

            $scope.toggleAccountSwitcher = function() {
                if (!UserService.IsLoggedIn()) {
                    return;
                }

                if ($scope.accountSwitcherModal) {
                    $scope.accountSwitcherModal.destroy();
                    return;
                }
                var accounts = window.__pageproof_quark__.accounts;
                var loggedInUserIds = accounts.getAccounts().map(function (account) { return account.userId });

                $scope.accountSwitcherModal = $scope.modalService.createWithComponent(
                    'AccountSwitcher',
                    {
                        padding: {
                            top: 0,
                            bottom: 0,
                            left: window.innerWidth > 750 ? 30 : 50,
                            right: window.innerWidth > 750 ? 30 : 50,
                        },
                        loggedInUserIds: loggedInUserIds,
                        onAddAccount: accounts.addAccount,
                        onSwitchAccount: function (userId) {
                            $scope.accountSwitcherModal.destroy();
                            accounts.switchAccount(userId);
                        },
                    },
                    function () {
                        $scope.accountSwitcherModal = null;
                    }
                );
            };

            shortcutService.watch('accountSwitcher', function () {
                $scope.toggleAccountSwitcher();
            })

            $scope.toggleTeamMenu = function () {
                $scope.menuOpen = !$scope.menuOpen;
                $scope.teamMenuOpen = !$scope.teamMenuOpen;
            };

            var $html = $(document.documentElement);
            $(window).on('keydown keyup', function(event) {
                var isDown = event.type === 'keydown';
                var altKey = event.altKey;
                if (isDown && altKey) {
                    $html.addClass('key-alt');
                } else if (!isDown) {
                    $html.removeClass('key-alt');
                }
            });

            $rootScope.$on('$locationChangeStart', function () {
                $scope.menuOpen = false;
            });

            $rootScope.$on('$routeChangeStart', function () {
                $scope.$$url = $location.url();
            });

            function setFlags(value) {
                var base = window.__pageproof_quark__.defaultFlagValues || {};
                $rootScope.flags = angular.extend({}, base, value || {});
            }
            $rootScope.$on('$destroy', window.__pageproof_quark__.localStorage.watch('pageproof.flags', function (value) {
                setFlags(value);
            }));
            setFlags(window.__pageproof_quark__.localStorage.get('pageproof.flags'));

            debugCallback = function (value) {
                $rootScope.debug = value === 'true';
            };
            debugCallback(window.__pageproof_quark__.localStorage.get('pageproof.debug'));
            window.__pageproof_quark__.localStorage.watch('pageproof.debug', debugCallback);

            $scope.goToPath = function(url) {
                $scope.toggleMenu();
                $location.url(url || '/');
            };

            $scope.goToAdminPath = function(url) {
                $scope.toggleTeamMenu();
                $scope.menuOpen = false;
                $location.url(url || '/team/members');
            };

            $scope.isShownLogo = true;
        }
    );
