/* Copyright (C) 2023 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
const SLIDER_FLOOR = -100;
const SLIDER_CEIL = 100;
const SLIDER_DEFAULT_VALUE = 0;
const IMG_SLIDER_COLOR_STORAGE_KEY = 'pageproof.app.slider-color';
const IMG_SLIDER_CUSTOM_COLOR_STORAGE_KEY = 'pageproof.app.slider-custom-color';
class ComparisonToolbarController extends BaseController{

    /**
     * Left version for version dropdown selected
     * @type {string}
     */
    leftSelectedVersion = null;

    /**
     * right version for version dropdown selected
     * @type {string}
     */
    rightSelectedVersion = null;

    /**
     * by default center has active class, other two possible values - left, right
     * @type {string}
     */
    activeClass = "center";

    /**
     * Users' color selection for the slider
     * @type {string}
     */
     savedImgSliderColor = window.localStorage.getItem(IMG_SLIDER_COLOR_STORAGE_KEY);

    /**
     * Users' custom color selection for the slider
     * @type {string}
     */
     savedImgSliderCustomColor = window.localStorage.getItem(IMG_SLIDER_CUSTOM_COLOR_STORAGE_KEY);

    /**
     * rzslider definition
     * @type {{value: number, options: {id: string, floor: number, ceil: number, onChange: (function())}}}
     */
    comparisonSlider = {
        value: SLIDER_DEFAULT_VALUE,
        options: {
            id: 'comparison-slider',
            floor: SLIDER_FLOOR,
            ceil: SLIDER_CEIL,
            hideLimitLabels: true,
            boundPointerLabels: false,
            translate: (value) => {
                return this.difference;
            },
            onChange: (id, value) => {
                this.updateOpacities(value);
            }
        }
    };

    constructor($q, $scope, $timeout, shortcutService){
        super();

        this.$$ = {$q, $scope, $timeout, shortcutService};

        this.$$.$scope.$watchGroup([
            () => (this.mergeStatus),
            () => (this.difference)
        ], ([]) => {
            // If user has clicked on find difference/show difference button and some difference value has defined, than refresh the slider pointer to default value 0
                this.resetSlider();
                this.getDropDownOptions();
        });

        this.$$.$scope.$watch(() => this.dropDownOptions, (options) => {
            this.sortDropDownOptions();
        });

        // This is a reference to the imgSlider and not the comparison slider
        // One is under zoom and the other is on the main toolbar. This is the one on the main toolbar
        this.$$.$scope.$watch(() => this.imgSliderStatus, () => {
            if (!this.imgSliderStatus && this.mergeStatus) {
                this.changeDropdown(this.dropDownSelectedOption);
                this.updateDimension();
            }

            if (this.imgSliderStatus) {
                this.updateImgSliderColor(this.savedImgSliderColor);
            }
        });

        this.beforeDestroy(this.$$.shortcutService.watch('zoomIn', () => {
            if (!this.imgSliderStatus && this.mergeStatus) {
                this.flipVersions();
            }else if (this.mergeStatus || this.isLinked) {
                this.zoomIn();
            }
        }));

        this.beforeDestroy(this.$$.shortcutService.watch('zoomOut', () => {
            if (!this.imgSliderStatus && this.mergeStatus) {
                this.flipVersions();
            }else if (this.mergeStatus || this.isLinked) {
                this.zoomOut();
            }
        }));
    }

    updateCompareProofCtrl = (newVersionData) => {
        this.updateCompareCtrl({
            newVersionData: newVersionData,
        });
    }

    toggleGridInProofCtrl(type) {
        this.toggleGrid({
            type: type
        });
    }

    togglePlayPauseInProofCtrl(versionObj) {
        this.togglePlayPause({
            versionObj: versionObj
        });
    }

    canShowControls(versionObj) {
        return versionObj.proofData && versionObj.proofData.video && !this.isLinked;
    }

    changePage(pageNumber, type) {
        this.changePageInProofCtrl({
            pageNumber: pageNumber,
            type: type
        });
    }

    updateOpacities(sliderValue) {
        this.calculateNewOpacities(sliderValue).then((opacityObj) => {
            this.updateScreenOpacity({
                opacityObj: opacityObj
            });
        });
    }

    calculateNewOpacities(sliderValue) {
        let leftOpacity = 0;
        let rightOpacity = 0;
        let diffOpacity = 1;
        return this.$$.$q((resolve, reject) => {
            if (sliderValue < 0) {
                leftOpacity = Math.abs(sliderValue / SLIDER_CEIL);
                diffOpacity = (SLIDER_CEIL-Math.abs(sliderValue)) / SLIDER_CEIL;
            }
            if (sliderValue > 0) {
                rightOpacity = sliderValue / SLIDER_CEIL;
                diffOpacity = (SLIDER_CEIL-sliderValue) / SLIDER_CEIL;
            }
            let opacityObj = {
                leftOpacity: leftOpacity,
                diffOpacity: diffOpacity,
                rightOpacity: rightOpacity
            };
            if (opacityObj) {
                resolve(opacityObj);
            } else {
                reject(error);
            }
        });
    }

    smartCompareActive() {
        return this.compareMode && this.mergeStatus;
    }

    canFindDifference() {
        return this.canCompare && !this.compareMode && !this.isLoading;
    }

    canShowText() {
        return !this.isLoading && (!this.sameSize || !this.allowedExtensions) && !this.mergeStatus && !this.isLinked && !this.areVideos();
    }

    canShowPlay() {
        return this.isLinked && this.areVideos();
    }

    areVideos() {
        return this.leftImageData && this.leftImageData.proofData && this.leftImageData.proofData.video
            && this.rightImageData && this.rightImageData.proofData && this.rightImageData.proofData.video;
    }

    isVideo(data) {
        return data && data.proofData && data.proofData.video;
    }

    showRightImage() {
        this.setActiveClass('right', SLIDER_CEIL);
        this.updateOpacities(SLIDER_CEIL);
        this.updateDimension();
    }

    showLeftImage() {
        this.setActiveClass('left', SLIDER_FLOOR);
        this.updateOpacities(SLIDER_FLOOR);
        this.updateDimension();
    }

    showDiffImage() {
        this.setActiveClass('center', SLIDER_DEFAULT_VALUE);
        this.updateOpacities(SLIDER_DEFAULT_VALUE);
    }

    setActiveClass(className, sliderValue) {
        this.activeClass = className;
        this.comparisonSlider.value = sliderValue;
    }

    isActive(classType) {
        return this.activeClass === classType;
    }

    resetSlider() {
        this.comparisonSlider.value = SLIDER_DEFAULT_VALUE;
        this.$$.$scope.$broadcast('rzSliderForceRender');
        this.$$.$timeout(() => {
            this.$$.$scope.$broadcast('reCalcViewDimensions');
        }, 10);
    }

    getDropDownOptions() {
        this.dropDownOptions = [];
        this.dropDownOptions.push({
            'value': 'left',
            'text': this.isSameProof
                ? 'proof-comparison.dropdown.version'
                : 'proof-comparison.dropdown.proof',
            'version': this.leftSelectedVersion || '1',
            'proof': 'A',
            'name': this.leftImageData.proofData.title,
        });
        this.dropDownOptions.push({
            'value': 'right',
            'text': this.isSameProof
                ? 'proof-comparison.dropdown.version'
                : 'proof-comparison.dropdown.proof',
            'version': this.rightSelectedVersion || '1',
            'proof': 'B',
            'name': this.rightImageData.proofData.title,
        });
        this.dropDownOptions.push({
            'value': 'both',
            'text': 'proof-comparison.dropdown.both',
            'version': 1000,
        });
        this.sortDropDownOptions();
        this.updateDropdownSelection('diff');
    }

    sortDropDownOptions() {
        if (this.dropDownOptions) {
            this.dropDownOptions.sort((a,b) => {
                return a.version - b.version;
            });
        }
    }

    updateDropdownSelection(value) {
        this.dropDownSelectedOption = value;
        this.compareViewMode = value;
    }
    
    flipVersions() {
        let option = (this.dropDownSelectedOption === 'right') ? 'left' : 'right';

        this.prepareForFlip({ type: option });
        this.compareViewMode = option;
        this.changeDropdown(option);
    }

    changeDropdown = (option) => {
        let value = option.value || option;
        switch (value) {
            case 'diff': this.showDiffImage(); break;
            case 'left': this.showLeftImage(); break;
            case 'right': this.showRightImage(); break;
            case 'both': this.toggleImages(); break;
        }
        this.updateDropdownSelection(value);
    }

    updateImgSliderColor = (color) => {
        const imgSlider = document.getElementsByClassName('app__compare__img-comp-slider')[0];

        imgSlider.style.setProperty('--sliderColor', color);
    }

    changeCustomColorImgSlider = (color) => {
        if (!color) {
            return;
        }

        this.savedImgSliderCustomColor = color;

        window.localStorage.setItem(IMG_SLIDER_CUSTOM_COLOR_STORAGE_KEY, color);

        this.changeColorImgSlider(color);
    }

    changeColorImgSlider = (color) => {
        if (!color) {
            return;
        }

        this.updateImgSliderColor(color);

        this.savedImgSliderColor = color;

        window.localStorage.setItem(IMG_SLIDER_COLOR_STORAGE_KEY, color);
    }
}

function ComparisonToolbarDirective(directiveHelper) {
    return {
        restrict: 'E',
        controller: 'ComparisonToolbarController',
        controllerAs: 'comparisonToolbarCtrl',
        require: ['comparisonToolbar'],
        scope: true,
        replace: true,
        templateUrl: 'templates/partials/proof/components/proof-comparison-toolbar.html',
        link(scope, element, attrs, [comparisonToolbarCtrl]) {
            directiveHelper.callbackBinding(scope, attrs, 'canShowPrevArrow', comparisonToolbarCtrl, 'canShowPrevArrow');
            directiveHelper.callbackBinding(scope, attrs, 'canShowNextArrow', comparisonToolbarCtrl, 'canShowNextArrow');
            directiveHelper.callbackBinding(scope, attrs, 'findDifferences', comparisonToolbarCtrl, 'findDifferences');
            directiveHelper.callbackBinding(scope, attrs, 'createImageSlider', comparisonToolbarCtrl, 'createImageSlider');
            directiveHelper.callbackBinding(scope, attrs, 'toggleImages', comparisonToolbarCtrl, 'toggleImages');
            directiveHelper.callbackBinding(scope, attrs, 'updateCompareCtrl', comparisonToolbarCtrl, 'updateCompareCtrl');
            directiveHelper.callbackBinding(scope, attrs, 'updateScreenOpacity', comparisonToolbarCtrl, 'updateScreenOpacity');
            directiveHelper.callbackBinding(scope, attrs, 'toggleGrid', comparisonToolbarCtrl, 'toggleGrid');
            directiveHelper.callbackBinding(scope, attrs, 'zoomIn', comparisonToolbarCtrl, 'zoomIn');
            directiveHelper.callbackBinding(scope, attrs, 'zoomOut', comparisonToolbarCtrl, 'zoomOut');
            directiveHelper.twoWayBinding(scope, attrs, 'leftVideoPlayer', comparisonToolbarCtrl, 'leftVideoPlayer');
            directiveHelper.twoWayBinding(scope, attrs, 'rightVideoPlayer', comparisonToolbarCtrl, 'rightVideoPlayer');
            directiveHelper.twoWayBinding(scope, attrs, 'compareViewMode', comparisonToolbarCtrl, 'compareViewMode');
            directiveHelper.callbackBinding(scope, attrs, 'togglePlayPause', comparisonToolbarCtrl, 'togglePlayPause');
            directiveHelper.callbackBinding(scope, attrs, 'toggleBothPlayers', comparisonToolbarCtrl, 'toggleBothPlayers');
            directiveHelper.callbackBinding(scope, attrs, 'changePageInProofCtrl', comparisonToolbarCtrl, 'changePageInProofCtrl');
            directiveHelper.callbackBinding(scope, attrs, 'changePagePrev', comparisonToolbarCtrl, 'changePagePrev');
            directiveHelper.callbackBinding(scope, attrs, 'changePageNext', comparisonToolbarCtrl, 'changePageNext');
            directiveHelper.callbackBinding(scope, attrs, 'updateDimension', comparisonToolbarCtrl, 'updateDimension');
            directiveHelper.callbackBinding(scope, attrs, 'prepareForFlip', comparisonToolbarCtrl, 'prepareForFlip');
            directiveHelper.controllerBinding(scope, attrs, 'bind', comparisonToolbarCtrl); //todo delete- do need that now
            scope.$applyAsync(function(){
                // comparisonToolbarCtrl.init();
            });
        }
    };
}

function ComparisonToolbarOptionsDirective ($parse) {
    return {
        require: ['comparisonToolbar'],

        link (scope, element, attrs, ctrls) {
            let [ comparisonToolbarCtrl ] = ctrls;

            let toolbarOptionsAttr = $parse(attrs.comparisonToolbarOptions);
            scope.$watch(() => toolbarOptionsAttr(scope), (options) => {
                Object.keys(options).forEach((key) => {
                    comparisonToolbarCtrl[key] = options[key];
                });
            }, true);
        }
    };
}

app
    .controller('ComparisonToolbarController', ComparisonToolbarController)
    .directive('comparisonToolbar', ComparisonToolbarDirective)
    .directive('comparisonToolbarOptions', ComparisonToolbarOptionsDirective);
