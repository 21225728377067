/* Copyright (C) 2024 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
const printTemplate = [`
    <!DOCTYPE html>
    <html lang="en">
    <head>
        <meta charset="utf-8">
        <base href="${document.baseURI}">
        <link href="css/print-legacy.css" rel="stylesheet">
    </head>
    <body onload="setTimeout(function () { print(); }, 1000)">
        `, `
    </body>
    </html>
`];

const NoTodosError = createError('NoTodosError');
const NoCommentsError = createError('NoCommentsError');

class ProofPrintControllerLegacy {
    dateFormat = 'h:mma, DD MMMM YYYY';

    static PROOF_LOAD_OPTIONS = {
        proof: false,
        versions: true,
        status: true,
        workflow: true,
        finalApprover: true,
        comments: true,
        coOwners: true
    };

    constructor (
        $q,
        $timeout,
        $element,
        $routeParams,
        $location,
        $rootScope,
        $translate,
        $exceptionHandler,
        $sce,
        proofRepositoryService,
        avatarRepositoryService,
        commentRepositoryService,
        backendService,
        userService,
        appService,
        modalService,
        PPUser,
        PPProof,
        PPProofStatus,
        UserService,
        SegmentIo,
        sdk
    ) {
        this.$$ = {
            $q,
            $timeout,
            $element,
            $routeParams,
            $location,
            $rootScope,
            $translate,
            $exceptionHandler,
            $sce,
            proofRepositoryService,
            avatarRepositoryService,
            commentRepositoryService,
            backendService,
            userService,
            appService,
            modalService,
            PPUser,
            PPProof,
            PPProofStatus,
            UserService,
            SegmentIo,
            sdk,
        };
        if (this.$$.$location.path() === '/printLegacy.php') {
            this.scheme = 'print';
            this.$$.$element = $('[ng-view]');
            this.print(this.$$.$routeParams.proofId, this.$$.$routeParams.printType);
        } else if (this.$$.$element) {
            // DON'T REMOVE CONDITION ABOVE, DUE TO MANUAL INSTANTIATION IN PROOF CONTROLLER
            this.scheme = 'frame';
            this.listenForEvent();
        }
    }

    listenForEvent () {
        this.$$.$rootScope.$on('print', (event, data) => {
            if (angular.isDefined(data.proofId)) {
                this.canPrint(data.proofId, data.printType)
                    .then(() => {
                         this.$$.SegmentIo.track(63, {
                             'proof id': data.proofId,
                             'type': data.printType,
                             'system': 'legacy',
                         }); // Print Proof
                    })
                    .then(() => {
                        return this.$$.$translate('print-proof.generating-' + data.printType + '.message').then(message => {
                            this.$$.UserService.showLoader();
                            this.$$.appService.showLoader(data.message || message);
                        });
                    })
                    .then(() => this.print(data.proofId, data.printType))
                    .then(proof => console.log(proof))
                    .then(() => {
                        this.$$.appService.hideLoader();
                    })
                    .catch((err) => {
                        if (err instanceof NoTodosError) {
                            this.$$.SegmentIo.track(30, {
                                'proof id': data.proofId,
                            });

                            this.$$.backendService.fetch('proof.load', {proofId: data.proofId})
                                .fetch(data.proofId)
                                .then((proofData) => this.$$.PPProof.from(proofData))
                                .then((proof) => {
                                    let i18n;

                                    switch (proof.status) {
                                        case this.$$.PPProofStatus.PROOFING:
                                        case this.$$.PPProofStatus.FINAL_APPROVING:
                                            i18n = 'no-todos-yet';
                                            break;
                                        case this.$$.PPProofStatus.CHANGES_REQUESTED:
                                        case this.$$.PPProofStatus.AWAITING_NEW_VERSION:
                                            i18n = 'no-todos-anymore';
                                            break;
                                        case this.$$.PPProofStatus.APPROVED:
                                            i18n = 'no-todos-approved';
                                            break;
                                        case this.$$.PPProofStatus.CLOSED:
                                            i18n = 'no-todos-closed';
                                            break;
                                    }

                                    if (i18n) {
                                        this.$$.modalService.translate(
                                            'print-proof.error.' + i18n + '.title', 'print-proof.error.' + i18n + '.message',
                                            [{ type: 'primary', text: 'print-proof.error.' + i18n + '.button' }]
                                        );
                                    } else {
                                        this.$$.$exceptionHandler(Error(`Unhandled no-todos message for proof with status #{proof.status}.`));
                                    }
                                });
                        } else if (err instanceof NoCommentsError) {
                            const isBrief = window.isBriefId(data.proofId);
                            const type = isBrief ? "brief" : "proof";
                            this.$$.modalService.translate(
                                'print-proof.error.no-comments.title', 'print-' + type + '.error.no-comments.message',
                                [{ type: 'primary', text: 'print-proof.error.no-comments.button' }]
                            );
                        } else {
                            this.$$.$exceptionHandler(err);
                        }
                    });
            }
        });
    }

    canPrint (proofId, printType) {
        return this.$$
            .backendService
            .fetch('proof.comments.since', {
                proofId: proofId,
                timeStamp: '0001-01-01T00:00:00'
            })
            .data()
            .then(data => {
                return this.$$.$q((resolve, reject) => {
                    if (printType === 'todos') {
                        let todoCount = Object.keys(data.Pages).reduce((todoCount, pageNumber) => {
                                // Add up the changes for each page (minus the todos that are marked as done)
                                return todoCount + data.Pages[pageNumber].Changes;
                            }, 0),
                            hasTodos = todoCount > 0;

                        (hasTodos ? resolve : reject)(
                            hasTodos ? todoCount : NoTodosError()
                        );
                    } else if (printType === 'comments') {
                        let commentCount = Object.keys(data.Pages).reduce((a, b) => {
                            return a + data.Pages[b].Count;
                        }, 0),
                            hasComments = commentCount > 0;

                        (hasComments ? resolve : reject)(
                            hasComments ? commentCount : NoCommentsError()
                        );
                    } else {
                        resolve();
                    }
                });
            });
    }

    print (proofId, printType) {
        return this.load(proofId, printType)
            .then((proof) => {
                this.orderCommentsBy = (proof.isAudio || proof.isVideo) ? 'time' : '-createdAt';
                this.type = printType;
                this.user = this.$$.userService.getUser();
                this.proof = proof;
                this.proof.isBrief = window.isBriefId(proofId);
                this.proof.approvingFinalApproverIds = proof.approvingFinalApproverUsers &&  proof.approvingFinalApproverUsers.map(user => user.id);
                this.proof.ownerMessageUser = proof.approvingFinalApproverUsers && proof.approvingFinalApproverUsers.find(approver => approver.id === proof.ownerMessageUserId);
                if (!proof.ownerMessageUser && proof.ownerMessageUserId) {
                    return this.$$.sdk.users.byId(proof.ownerMessageUserId)
                        .then((ownerMessageUser) => {
                            this.proof.ownerMessageUser = ownerMessageUser;
                        })
                }
                return Promise.resolve();
            })
            .then(() => {
                this.date = moment();
                this.$$.$timeout(this.build.bind(this), 100);
            })
    }

    load (proofId, printType) {
        return this.loadProof(proofId).then((proof) => {
            return this.$$.$q.all([
                this.extractApprovingFinalApprovers(proof),
                this.filterCommentsByPrintType(proof, printType)
                    .then(() => this.decryptOrLoadComments(proof))
                    .then(() => this.calculatePinPositions(proof)),
                this.$$.sdk.proofs.images(proof.id)
                    .then((thumbnails) => {
                        proof.thumbnails = thumbnails;
                        return this.decryptOrLoadPreviews(proof, printType);
                    }),
                this.decryptOrLoadThumbnail(proof),
                this.loadAllAvatars(proof)
            ]).then(() => proof);
        });
    }

    filterCommentsByPrintType (proof, printType) {
        return this.$$.$q((resolve) => {
            proof.pages.forEach((page) => {
                page.comments = page.comments.filter((comment) => {
                    const include = !comment.isPrivate && (
                        (printType === 'todos' && comment.isTodo && !comment.isDone) ||
                        printType === 'comments'
                    );
                    if (include && comment.replies) {
                        // If we're going to include the comment make sure we at least filter out it's private replies.
                        comment.replies = comment.replies.filter((reply) => !reply.isPrivate);
                    }
                    return include;
                });
            });
            resolve(proof);
        });
    }

    extractApprovingFinalApprovers (proof) {
        if (proof.workflow) {
            let step = proof.workflow.steps[proof.workflow.steps.length - 1];
            if (step.isFinalApproverStep) { // Double check final approver step
                proof.approvingFinalApproverUsers = step.users.filter(user => user.decision === 1);
                return Promise.all(proof.approvingFinalApproverUsers.map(user => this.loadAvatarForUser(user)));
            }
        }
    }

    getCommentsFromProof (proof) {
        let comments = [];
        proof.pages.forEach((page) => {
            page.comments.forEach((comment) => {
                comments.push(comment);
                if (comment.isParent) {
                    comment.replies.forEach((reply) => {
                        comments.push(reply);
                    });
                }
            });
        });
        return comments;
    }

    loadUser (userId) {
        return this.$$.backendService.fetch('user.load', {userId})
            .data().then((userData) => {
            return this.$$.PPUser.from(userData);
        });
    }

    loadAllAvatars (proof) {
        let wait = [];

        wait.push(this.loadUser(proof.ownerId).then((user) => {
            proof.ownerUser = user;
            return this.loadAvatarForUser(proof.ownerUser);
        }));
        proof.coOwners.forEach((user) => {
            wait.push(this.loadAvatarForUser(user));
        });

        return this.$$.$q.all(wait);
    }

    loadAvatarForUser (user) {
        if (user) {
            return this.$$.avatarRepositoryService.get({userId: user.id}).then((data) => {
                user.hasAvatar = data.hasAvatar;
                user.avatar = data.image;
                user.isRegistered = data.isRegistered;
            });
        }
    }

    decryptOrLoadComments (proof) {
        let comments = this.getCommentsFromProof(proof);
        return this.$$.$q.all(comments.map((comment) => {
            return this.decryptOrLoadComment(comment);
        }));
    }

    decryptOrLoadComment (comment) {
        return this.$$.$q.when()
            .then(() => {
                if (comment.snapshot) {
                    return this.loadSnapshot(comment);
                }
            })
            .then(() => {
                const id = this.$$.commentRepositoryService.getCommentIdentifier(comment);
                return this.$$.commentRepositoryService.get(id).then((decryptedComment) => {
                    comment.decryptedComment = decryptedComment;

                    const obj = window.generalfunctions_parseCommentText(decryptedComment);
                    const html = window.__pageproof_quark__.sdk.util.comments.html(obj.tokens);
                    comment.html = this.$$.$sce.trustAsHtml(html);
                });
            });
    }

    decryptOrLoadThumbnail (proof) {
        return this.loadThumbnail(proof.fileId)
            .then((blob) => (proof.thumbnail = URL.createObjectURL(blob)))
            .catch(() => (proof.thumbnail = null));
    }

    decryptOrLoadPreviews (proof, printType) {
        if (!proof.isVideo && !proof.isWeb && printType !== 'approval') {
            return this.$$.$q.all(
                proof.pages.filter((page) => {
                    return page.comments.length;
                }).map((page) => {
                    return this.decryptOrLoadPreview(proof, page);
                })
            );
        } else if (proof.isStatic && printType === 'approval') {
            return this.decryptOrLoadPreview(proof, proof.pages[0])
        }
    }

    decryptOrLoadPreview (proof, page) {
        return this.loadPreview(proof, page).then((blob) => {
            page.image.high = URL.createObjectURL(blob);
            return window.generalfunctions_preloadImagePromise(page.image.high);
        });
    }

    loadSnapshot(comment) {
        return this.$$.sdk.files.preview(comment.snapshot.id, 1, 'low')
            .then(image => {
                comment.snapshot.url = URL.createObjectURL(image);
            })
            .catch(() => {
                // ignore errors
            });
    }

    loadPreview (proof, page) {
        const thumbnail = proof.thumbnails.images.pages
            .filter((_page) => _page.number === page.pageNumber)[0];
        const {version, envelope} = proof.thumbnails.envelope;
        return fetch(thumbnail.high.url)
            .then(response => response.blob())
            .then(data => this.$$.sdk.encryption.decrypt({ version, data, envelope }));
    }

    loadProof(proofId) {
        return this.$$.proofRepositoryService.getById(proofId, ProofPrintControllerLegacy.PROOF_LOAD_OPTIONS);
    }

    loadThumbnail (fileId) {
        return this.$$.sdk.files.thumbnail(fileId);
    }

    calculatePinPositions (proof) {
        proof.pages.forEach((page) => {
            page.comments.forEach((comment) => {
                comment.pins.forEach((pin) => {
                    this.calculatePinPosition(pin, comment);
                });
            });
        });
    }

    calculatePinPosition (pin, comment) {
        pin._x = ((0.5 + pin.x) * 100);
        pin._y = ((0.5 + pin.y) * 100);

        pin._x2 = ((0.5 + (pin.x2 || pin.x)) * 100);
        pin._y2 = ((0.5 + (pin.y2 || pin.y)) * 100);

        pin._w = Math.abs(pin._x2 - pin._x);
        pin._h = Math.abs(pin._y2 - pin._y);

        pin._pin = {
            top: pin._y + '%',
            left: pin._x + '%'
        };

        pin._box = {
            width: pin._w + '%',
            height: pin._h + '%',
            top: pin._y + '%',
            left: pin._x + '%',
            transform: ''
        };
        pin._highlight = comment.getHighlight();

        if (pin.y2 < pin.y) {
            pin._box.transform += 'translateY(-100%) ';
        }

        if (pin.x2 < pin.x) {
            pin._box.transform += 'translateX(-100%)';
        }

        if (pin.fill) {
            let svg = pin.fill.image;
            const fill = pin.isDone ? '#138b3b' : pin.isTodo ? '#E51C23' : '#7f8c8d';
            switch (pin.fill.type) {
                case 'draw': {
                    svg = svg.replace(/stroke: ?([\w#(),]+);/g, 'stroke: ' + fill + ';');
                    svg = svg.replace(/stroke="?([\w#(),]+)"/g, 'stroke="' + fill + '"');
                    break;
                }
                case 'text': {
                    svg = svg.replace(/<path\s/g, '<path fill="' + fill + '" opacity="0.35" ');
                    break;
                }
            }
            pin._box.fill = 'data:image/svg+xml;base64,' + btoa(svg);
            pin._box.position = 'absolute';
        }
    }

    build () {
        // this.scheme = 'debug';
        switch (this.scheme) {
            case 'print':
                window.print();
                window.close();
                break;
            case 'debug':
            case 'frame':
                let blob =
                        new Blob([
                            printTemplate[0],
                            this.$$.$element.html(),
                            printTemplate[1]
                        ], {
                            type: 'text/html'
                        }),
                    url = URL.createObjectURL(blob);

                if (this.scheme === 'debug') {
                    open(url);
                } else {
                    let frame = document.createElement('iframe');
                    frame.src = url;
                    frame.style.visibility = 'hidden';
                    frame.style.position = 'absolute';
                    frame.style.top = '-100px';
                    frame.style.left = '-100px';
                    frame.style.width = frame.style.height = '0';
                    document.body.appendChild(frame);
                }
                break;
        }
    }
}

app.controller('ProofPrintControllerLegacy', ProofPrintControllerLegacy);
