/* Copyright (C) 2024 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

const privateKeyEnvelopeSuccess = {};
let lastSentDiagnosticInformation = {};

function sendDiagnosticInformation(proofId, err) {
    // Bugsnag.maxDepth = 100;
    if (lastSentDiagnosticInformation[proofId] && lastSentDiagnosticInformation[proofId] > Date.now() - 10000) {
        return;
    }
    try {
        window.__pageproof_quark__.troubleshooting.dumpBrokenProofDiagnosticInformation(proofId)
            .then((diagnosticInformation) => Bugsnag.notify('Diagnostic Information Report', 'Proof ID: ' + proofId, { diagnostic_information: diagnosticInformation, original_error: { message: err.message, stack: err.stack } }, 'info'));
        lastSentDiagnosticInformation[proofId] = Date.now();
    } catch (err) {
        Bugsnag.notifyException(err);
    }
}

function validateThumbnailUrlsList(images) {
    if (!images.envelope.envelope) {
        throw new Error('Thumbnail URL list does not contain an envelope for decryption.');
    }

    const firstPage = images.images.pages[0];

    const firstExpiryDate = new Date((firstPage.low || firstPage.high).expiryDate);
    if (+firstExpiryDate < Date.now()) {
        throw new Error('Thumbnail URL list has expired.');
    }
}

function validateEnvelope(sdk, envelope, privateKeys) {
    const id = envelope;
    if (!privateKeyEnvelopeSuccess[id]) {
        privateKeyEnvelopeSuccess[id] = sdk.crypto()
            .envelopeUnseal(envelope, privateKeys);
    }
    return privateKeyEnvelopeSuccess[id];
}
class ProofRepository {
    /**
     * The cache of loaded proofs.
     *
     * @type {PPProof[]}
     */
    cache = {};
    previousSteps = {};

    /**
     * @constructor
     * @ngInject
     */
    constructor (
        $q,
        apiService,
        backendService,
        userService,
        browserService,
        PPUser,
        PPProof,
        PPProofPage,
        PPProofComment,
        PPProofWorkflow,
        PPProofWorkflowStep,
        PPProofWorkflowUser,
        userRepositoryService,
        temporaryStorageService,
        UserService,
        $timeout,
        sdk
    ) {
        this.$q = $q;
        this.apiService = apiService;
        this.backendService = backendService;
        this.browserService = browserService;
        this.PPUser = PPUser;
        this.PPProof = PPProof;
        this.PPProofPage = PPProofPage;
        this.PPProofComment = PPProofComment;
        this.PPProofWorkflow = PPProofWorkflow;
        this.PPProofWorkflowStep = PPProofWorkflowStep;
        this.PPProofWorkflowUser = PPProofWorkflowUser;
        this.userRepositoryService = userRepositoryService;
        this.temporaryStorageService = temporaryStorageService;
        this.UserService = UserService;
        this.$timeout = $timeout;
        this.sdk = sdk;
        this.userService = userService;
    }

    /**
     * Load the proof (by proof id).
     *
     * @param {String} id
     * @param {Object} [options]
     * @returns {$q<PPProof>}
     */
    getById (id, options = {}) {
        let proof = new this.PPProof();

        return this.$getProofById(id, proof, options.referrer)
            .then(() => this.$populateProof(proof, options))
            .then(() => proof);
    }

    /**
     * Populates a proof object with it's data from the server.
     *
     * Options
     *  - proof (updates the core proof object)
     *  - status
     *  - versions
     *  - workflow
     *  - finalApprover (depends on 'workflow')
     *  - comments
     *  - coOwners
     *  - workflowManagers (depends on 'workflow')
     *  - video
     *  - all (enables everything - not recommended in production)
     *
     * @param {PPProof} proof
     * @param {Object} options
     * @returns {$q<PPProof>}
     */
    $populateProof (proof, options) {
        let tasks = [],
            before = [];

        // Make sure that all the defaults are set (so `all` can toggle them on)
        options = angular.extend({
            proof: false,
            status: false,
            versions: false,
            workflow: false,
            finalApprover: false,
            comments: false,
            coOwners: false,
            owner: false,
            editor: false,
            recipient: false,
            workflowManagers: false,
            video: false,
            decisionOptions: false,
            all: false
        }, options);

        // Enable every option when passing the `all` option
        if (options.all) {
            Object.keys(options).forEach((option) => options[option] = true);
        }

        // Automatically load the workflow when loading fa/co-owners/wfm
        if (options.finalApprover || options.workflowManagers) {
            if ( ! options.workflow) {
                // Just a heads up, in case this option was passed in by accident.
                console.warn('Loading proof workflow - one or more of your options depend on the workflow.');
            }
            options.workflow = true;
        }

        if (options.status) {
            tasks.push(this.$getStatusById(proof.id, proof));
        }

        if (options.versions) {
            tasks.push(this.$getVersionsById(proof.id, proof));
        }

        if (options.coOwners) {
            tasks.push(this.$getCoOwnersById(proof.id, proof));
        }

        if (options.owner && proof.ownerId) {
            tasks.push(this.$getOwnerById(proof.ownerId, proof));
        }

        //todo delete
        if (options.editor && proof.editorId) {
            tasks.push(this.$getEditorById(proof.editorId, proof));
        }

        if (options.editor && proof.editorIds.length) {
            tasks.push(this.$getEditorsById(proof.editorIds, proof));
        }

        if (options.recipient && proof.recipient) {
            tasks.push(this.$getRecipientById(proof.recipient, proof));
        }

        if (options.workflow && proof.workflowId) {
            tasks.push(this.$getWorkflowById(proof.workflowId, proof).then(() => {
                let tasks = [];

                if (options.workflowManagers) {
                    tasks.push(this.$getWorkflowManagersById(proof.workflow.id, proof));
                }
                return this.$q.all(tasks);
            }));
        }

        if (options.decisionOptions &&
            [window.__pageproof_quark__.sdk.Enum.ProofRole.UNLISTED_REVIEWER].includes(proof.role)) {
            tasks.push(this.$getDecisionOptions(proof.id, proof));
        }

        if (options.comments) {
            tasks.push(this.$getCommentsById(proof.id, proof));
        }

        if (options.video) {
            // Try to fetch only if user is a listed reviewer aka workflow user.
            // As if it's an unlisted reviewer, we don't have public key pair yet, hence will fetch later on time when we have public key pair.
            if (proof.isVideo && !proof.publicProofKeyPair) {
                tasks.push(this.$getVideoByProofId(proof.id, proof));
            } else {
                console.warn('Proof is not a video (proof id: ' + proof.id + ') - video data will not be loaded.');
            }
        }

        if (options.proof) {
            before.push(this.$getProofById(proof.id, proof, options.referrer));
        }

        return this.$q.all(before)
            .then(() => this.$q.all(tasks))
            .then(() => proof);
    }

    /**
     * Load the proof data (by proof id).
     *
     * @param {String} id
     * @param {PPProof} proof
     * @param {string} referrer
     * @returns {$q<PPProof>}
     */
    $getProofById (id, proof, referrer) {
        return this.backendService
            .fetch('proof.load', {
                proofId: id,
                referrer: referrer || undefined,
            })
            .data()
            .then((proofData) => {
                proof.updateFromProofData(proofData);
                return proof;
            }, (err) => (this.$q.reject(
                // Reject using the ResponseStatus (for backwards compat.) or the err object as a fallback
                (err && err.data && err.data.ResponseStatus != null)
                    ? err.data.ResponseStatus
                    : err
            )));
    }

    /**
     * Load the proof status string (by proof id).
     *
     * @param {String} id
     * @param {PPProof} proof
     * @returns {$q<PPProof>}
     */
    $getStatusById (id, proof) {
        return this.apiService
            .proofs.getProofStatusStr
            .fetch(id)
            .then((statusString) => {
                proof.updateFromStatusStringData(statusString);
                return proof;
            });
    }

    /**
     * Load the versions (by proof id).
     *
     * @param {String} id
     * @param {PPProof} proof
     * @returns {$q<PPProof>}
     */
    $getVersionsById (id, proof) {
        return this.apiService
            .proofs.getVersions
            .fetch(id)
            .then((versions) => {
                proof.updateFromVersionData(versions);
                return proof;
            });
    }

    /**
     * Load the versions without formatting return data
     * @param id
     * @returns {*|Promise.<TResult>}
     */
    $getVersions (id) {
        return this.apiService
            .proofs.getVersions
            .fetch(id)
            .then((versions) => {
                return versions;
            });
    }

    /**
     * Load the workflow (by workflow id).
     *
     * @param {String} id
     * @param {PPProof} proof
     * @returns {$q<PPProofWorkflow>}
     */
    $getWorkflowById (id, proof) {
        let workflow = proof.workflow = new this.PPProofWorkflow();

        return this.apiService
            .workflows.getDetailedById
            .fetch(id)
            .then((workflowData) => {
                return this.normaliseWorkflowData(workflow, workflowData);
            });
    }

    normaliseWorkflowData(workflow, workflowData) {
        let {WorkflowSteps: workflowSteps} = workflowData;
        let choiceUsers = [];  //will contain all tyep of proofer user data
        workflow.updateFromProofWorkflowData(workflowData);

        workflow.steps = workflowSteps.map((workflowStepData) => {
            let step = this.PPProofWorkflowStep.from(workflowStepData);
            step.updateFromProofWorkflowStepData(workflowStepData);

            step.users = workflowStepData.Users.map((workflowStepUser) => (
                this.PPProofWorkflowUser.from(workflowStepUser)
            ));
            step.users.forEach((user) => {
                choiceUsers.push(user);
            });
            return step;
        });

        workflow.prooferUsers = choiceUsers; //adding one more property to workflow object
        return workflow;
    }

    $getDecisionOptions (id, proof) {
        return this.sdk.proofs.decisions.getOptions(id)
            .then(decisionOptions => (proof.decisionOptions = decisionOptions));
    }

    /**
     * Loads all the comments on a proof.
     *
     * @param {String} id
     * @param {PPProof} proof
     * @returns {$q<PPProof>}
     */
    $getCommentsById (id, proof) {
        return this.apiService
            .proofs.getRecent
            .fetch({
                proofId: id,
                timeStamp: '0001-01-01T00:00:00'
            })
            .then((recentCommentData) => {
                proof.updateFromRecentCommentsData(recentCommentData);
                return proof;
            });
    }

    /**
     * Loads the video data for a proof.
     *
     * @param {String} proofId
     * @param {PPProof} proof
     * @returns {$q<PPProof>}
     */
    $getVideoByProofId (proofId, proof) {
        return this.sdk.proofs.videoCredentials(proofId)
            .then(data => {
                proof.updateFromVideoData(data);
                return proof;
            }).catch(err => {
                if (!proof.postmanPat) {
                    proof.postmanPat = true;
                    this.backendService.fetch('proof.report-issue-loading', {proofId})
                }
                if (window.Bugsnag) {
                    Bugsnag.notifyException(err);
                }
                return window.generalfunctions_sleep(2000)
                    .then(() => this.$getVideoByProofId(proofId, proof));
            })
            .finally(() => {
                proof.postmanPat = false;
            });
    }

    /**
     * Load the co owners (by workflow id).
     *
     * Requires the proof to have been loaded prior.
     *
     * @param {String} id
     * @param {PPProof} proof
     * @returns {$q<PPProof>}
     */
    $getCoOwnersById (id, proof) {
         return this.apiService
            .proofs.teamGetList
            .fetch(id)
            .then((teamData) => {
                if (teamData) {
                    return this.$q.all(
                        teamData
                        .filter(user => user.ProofCoOwner)
                        .map((user) => {
                            return this.userRepositoryService.get(user.UserId).then((userData) =>{
                                var user = new this.PPUser();
                                user.id = userData.userId;
                                user.email = userData.email;
                                user.name = userData.name;
                                return user;
                            });
                        })
                    ).then((coOwners) => {
                        proof.coOwners = coOwners;
                        proof.coOwnerIds = coOwners.map(user => user.id)
                    })
                }
            })
            .then(() => proof);
    }

    $getOwnerById (ownerId, proof) {
        return this.userRepositoryService.get(ownerId).then((userData) =>{
            var user = new this.PPUser();
            user.id = userData.userId;
            user.email = userData.email;
            user.name = userData.name;
            proof.ownerUser = user;
        })
        .then(() => proof);
    }

    $getEditorById (editorId, proof) {
        return this.userRepositoryService.get(editorId).then((userData) =>{
            var user = new this.PPUser();
            user.id = userData.userId;
            user.email = userData.email;
            user.name = userData.name;
            proof.editorUser = user;
        })
        .then(() => proof);
    }

    $getEditorsById (editorIds, proof) {
        return this.$q.all(editorIds.map((userId) => {
            if (userId) {
                return this.userRepositoryService.get(userId).then((userData) =>{
                    var user = new this.PPUser();
                    user.id = userData.userId;
                    user.email = userData.email;
                    user.name = userData.name;
                    proof.editorUsers.push(user);
                });
            }
        }))
        .then(() => proof);
   }

    $getRecipientById (recipientId, proof) {
        return this.userRepositoryService.get(recipientId).then((userData) =>{
            var user = new this.PPUser();
            user.id = userData.userId;
            user.email = userData.email;
            user.name = userData.name;
            proof.recipientUser = user;
        })
        .then(() => proof);
    }


    /**
     * Load the workflow managers (by workflow id).
     *
     * Requires the workflow to have been loaded prior.
     *
     * @param {String} id
     * @param {PPProof} proof
     * @returns {$q<PPProof>}
     */
    $getWorkflowManagersById (id, proof) {
        return this.apiService
            .workflows.teamGetList
            .fetch(id)
            .then((teamData) => {
                if (teamData) {
                    return this.$q.all(teamData.map((user) => {
                        if (user.WorkflowAdmin) {
                            proof.workflow.workflowManagerIds.push(user.UserId);

                            return this.userRepositoryService.get(user.UserId).then((userData) =>{
                                proof.workflow.workflowManagers.push(this.PPUser.from(userData));
                            });

                            //return this.apiService
                            //    .users.load
                            //    .fetch(user.UserId)
                            //    .then((userData) => {
                            //        proof.workflow.workflowManagers.push(this.PPUser.from(userData));
                            //    });
                        }
                    }));
                }
            })
            .then(() => proof);
    }

    toggleDownloadOption(fileId, downloadOption) {
        return this.backendService
            .fetch('proof.media.download-option.toggle', {
                fileId: fileId,
                downloadOriginal: (downloadOption) ? 1 : 0
            }).data().then(returnData => {
                console.debug("returnData after toggling download on/off - ", returnData );
                if (returnData.ResponseStatus === 'OK') {
                    return true;
                }
            }).catch((err) => {
                throw new Error(`Failed to set downloadOriginal to ${downloadOption} for file ${fileId}, because - ${err.message}`);
            });
    }

    /**
     * Sends a proof has been opened request to the API.
     *
     * @param {proofId} proofId
     */
    $registerProofHasSeen (proofId) {
        return (
            this.backendService.fetch('proof.seen', {ProofId: proofId})
        );
    }

    $getValidThumbnailUrls (proofId, fileId, tries = 6) {
        const user = this.userService.getUser();
        if (tries <= 0) {
            console.log(`Ran out of retries... couldn't load valid set of thumbnail urls.`);
            return this.$q.when(
                Promise.reject(
                    new Error('Could not load a valid set of thumbnail urls.')
                )
            );
        }
        // const startTime = performance.now();
        return this.$q.when(new Promise((resolve, reject) => {
            const id = {
                proofId: proofId,
                fileId: fileId,
                userId: user.id,
                publicKey: user.encryptionData.publicKeyServerPEM,
                _: 'thumbnail-images',
            };
            resolve(
                this.temporaryStorageService.auto(
                    id,
                    () => this.sdk.proofs.images(proofId),
                    Date.now() + (1000 * 60 * 30) // 30 minutes
                )
                .then((images) => {
                    validateThumbnailUrlsList(images);
                    return validateEnvelope(this.sdk, images.envelope.envelope, this.sdk.keyPairs.getPrivateKeyPEMs())
                        .then(
                            () => {
                                validateThumbnailUrlsList(images);
                                // if (tries === 5) {
                                //     const endTime = performance.now();
                                //     console.log('took', (endTime - startTime) + 'ms');
                                // }
                                return images;
                            },
                            () => {
                                throw new Error('Thumbnail URL list does not have a decryptable envelope.');
                            }
                        );
                })
                .catch((err) => {
                    if (err.message !== 'Thumbnail URL list has expired.') {
                        if (window.Bugsnag) {
                            Bugsnag.notifyException(err);
                        }
                        sendDiagnosticInformation(proofId, err);
                        this.backendService.fetch('proof.report-issue-loading', {proofId});
                    }
                    console.error(err);
                    console.log(`Couldn't get valid thumbnail urls, retrying in a couple of seconds...`);
                    return this.temporaryStorageService.remove(id)
                        .catch((idbErr) => {
                            if (window.Bugsnag) {
                                Bugsnag.notifyException(idbErr);
                            }
                        })
                        .then(() => window.generalfunctions_sleep(2000))
                        .then(() => this.$getValidThumbnailUrls(proofId, fileId, tries - 1))
                        .then((images) => {
                            // if (tries === 5) {
                            //     const endTime = performance.now();
                            //     console.log('took', (endTime - startTime) + 'ms');
                            // }
                            return images;
                        });
                })
            );
        }));
    }

    _shouldLoadHighRes(url, proof) {
      return this.$q((resolve, reject) => {
          // QUIRK: customer unable to review their really large proofs on mobile devices
          if (proof.teamId === '3603' && this.browserService.is('mobile')) {
              return resolve(false);
          }
          calculateImageDimensions(url, (err, dimensions) => {
              if (err) {
                  reject(err);
              } else {
                  const longest = Math.max(dimensions.width, dimensions.height);
                  const max = this.UserService.config.MaxImageSizeBreak;
                  resolve(longest < max);
              }
          });
      });
    }

    loadPagePreview(page, proof, shouldLoadSprite) {
        const fileId = proof.fileId;
        const proofId = proof.id;
        const loadThumbnail = (quality) => {
            return this.$getValidThumbnailUrls(proofId, fileId)
                .then((file) => {
                    const thumbnail = file.images.pages.filter(p => (p.number === page.pageNumber))[0];
                    const {version, envelope} = file.envelope;
                    let qualityImage = thumbnail[quality];
                    if (quality === 'ss') {
                        qualityImage = file.images.sprite;
                    }
                    if (qualityImage === null) {
                        // quality doesn't exist
                        return null;
                    }
                    return fetch(qualityImage.url)
                        .then(response => response.blob())
                        .then(data => this.sdk.encryption.decrypt({ version, data, envelope }))
                        .then(data => URL.createObjectURL(data));
                })
                .catch((err) => {
                    if (window.Bugsnag) {
                        Bugsnag.notifyException(err);
                        Bugsnag.notifyException(new Error('Falling back to legacy thumbnail URL.'));
                    }
                    return (
                        this.sdk.files.preview(fileId, page.pageNumber, quality)
                            .then(data => URL.createObjectURL(data))
                    );
                });
        }

        const lo = (
            page.image.low
                ? this.$q.when(page.image.low)
                : loadThumbnail('low')
                    .then(url => (page.image.low = url))
        );

        const setHighRes = (url) => {
            this.$timeout(() => {
                page.image.high = url;
            });
        };

        const high = page.image.high;
        const sprite = proof.sprite;
        page.image.high = null;
        lo.then(url => {
            return this._shouldLoadHighRes(url, proof);
        }).then((shouldLoadHighRes) => {
            if (shouldLoadHighRes) {
                if (high) {
                    setHighRes(high);
                } else {
                    loadThumbnail('high')
                        .then(url => setHighRes(url));
                }
            }
        }).then(() => {
            if (shouldLoadSprite && !sprite) {
                loadThumbnail('ss')
                    .then(url => {
                        proof.sprite = url;
                    });
            }
        });
        return lo;
    }
}

app.factory('proofRepositoryService', function ($injector) {
    return $injector.instantiate(ProofRepository);
});
