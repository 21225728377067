/* Copyright (C) 2024 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
const VIDEO_PLAYER_CONTAINER_PADDING_LEFT = 100;
const VIDEO_PLAYER_CONTAINER_PADDING_RIGHT = 50;

const ZOOM_LEVELS = [
    25 / 100,
    50 / 100,
    75 / 100,
    100 / 100,
    150 / 100,
    200 / 100,
    400 / 100,
    1000 / 100,
];

class VideoProofController extends GenericProofController {

    freeDrawingAvailable = false;

    /**
     * @constructor
     */
    constructor () {
        super();
        const that = this;

        this.$$import(this.$$dependencies([
            '$scope',
            'scopeService',
            'eventService',
            'browserService',
            'sdk',
            'modalService',
            'backendService',
        ]));

        this.load();

        this.lastScrubbedFrame = null;

        this.zoomProps = {
            onChange: level => that.videoPlayer.setZoom(level / 100),
        };

        this.isGifFile = () => that.proof && that.proof.extension === 'gif';

        this.isScrubberVisible = () => !that.isGifFile();

        if (this.registerGetVideoPlayerTime) {
            this.registerGetVideoPlayerTime(() => that.videoPlayer.currentTime);
        }
    }

    /**
     * Called when the proof starts to load.
     *
     * Does not get called if the user does not intend to stay on the page.
     *
     * @see {GenericProofController.load}
     */
    load() {
        return super.load()
            .then(() => this.loadVideoPreview())
            .then(() => this.didLoad());
    }

    toggleFocusMode = () => {
        if (this.isMobile) return false;
        this.focusMode = !this.focusMode;
        this.toggleFocusModeInHeader(this.focusMode);
        if (this.focusMode) {
            this.videoPlayer.setZoomToFill();
        } else {
            setTimeout(() => this.fit());
        }
    }

    /**
     *
     */
    initWatchers () {
        super.initWatchers();
        this.initVideoKeyboardShortcuts();

        this.beforeDestroy(this.$watch('isCommenting', (isCommenting) => {
            if (isCommenting) {
                // If the user has initialised commenting, pause the video
                this.videoPlayer.pause();
            }
        }));
    }

    /**
     *
     */
    initKeyboardShortcuts () {
        super.initKeyboardShortcuts();

        // Toggle the video player's playing state (default key binding: [space])
        this.beforeDestroy(this.$$.shortcutService.watch('playPause', () => {
            // Note: The video player controller can not be available if the directive hasn't loaded (template wise)
            if (this.videoPlayer) {
                this.videoPlayer.toggle();
            }
        }));

        // Toggle the video player's fullscreen state (default key binding: [ctrl+f])
        this.beforeDestroy(this.$$.shortcutService.watch('fullScreen', () => {
            if (this.videoPlayer) {
                this.toggleFullscreen();
            }
        }));
    }

    _getZoomOptions() {
        const current = this.videoPlayer.getZoom();
        let previous = ZOOM_LEVELS[0];
        let next = ZOOM_LEVELS[ZOOM_LEVELS.length - 1];
        ZOOM_LEVELS.some(level => {
            if (level < current) {
                previous = level;
            }
            if (level > current) {
                next = level;
                return true;
            }
        });
        return {previous, next};
    }

    zoomIn() {
        this.videoPlayer.setZoom(this._getZoomOptions().next, true);
    }

    zoomOut() {
        this.videoPlayer.setZoom(this._getZoomOptions().previous, true);
    }

    nudgeZoomIn() {
        this.videoPlayer.setZoom(this.videoPlayer.getZoom() + 0.05, true);
    }

    nudgeZoomOut() {
        this.videoPlayer.setZoom(this.videoPlayer.getZoom() - 0.05, true);
    }

    initVideoKeyboardShortcuts () {
        this.beforeDestroy(this.$$.shortcutService.watch('zoomIn', () => {
            this.zoomIn();
        }));

        this.beforeDestroy(this.$$.shortcutService.watch('zoomOut', () => {
            this.zoomOut();
        }));

        this.beforeDestroy(this.$$.shortcutService.watch('nudgeZoomIn', () => {
            this.nudgeZoomIn();
        }));

        this.beforeDestroy(this.$$.shortcutService.watch('nudgeZoomOut', () => {
            this.nudgeZoomOut();
        }));

        this.beforeDestroy(this.$$.shortcutService.watch('zoomFit', () => {
            this.fit();
        }));

        this.beforeDestroy(this.$$.shortcutService.watch('zoom100', () => {
            this.videoPlayer.setZoom(1, true);
        }));

        this.beforeDestroy(this.$$.shortcutService.watch('zoom200', () => {
            this.videoPlayer.setZoom(2, true);
        }));

        this.beforeDestroy(this.$$.shortcutService.watch('zoom400', () => {
            this.videoPlayer.setZoom(4, true);
        }));

        // Scrub to next frame (default key binding: [.)
        this.beforeDestroy(this.$$.shortcutService.watch('nextFrame', () => {
            if (!this.isGifFile()) {
                this.scrubToFrame(1);
            }
        }));

        // Scrub to the previous frame (default key binding: [,])
        this.beforeDestroy(this.$$.shortcutService.watch('previousFrame', () => {
            if (!this.isGifFile()) {
                this.scrubToFrame(-1);
            }
        }));

        // Skip 5 seconds ahead (default key binding: [right])
        this.beforeDestroy(this.$$.shortcutService.watch('nextPage', () => {
            if (!this.isGifFile() && !this.videoPlayer.isShortVideo) {
                this.skipForward();
            }
        }));

        // Skip backwards 5 seconds (default key binding: [left])
        this.beforeDestroy(this.$$.shortcutService.watch('previousPage', () => {
            if (!this.isGifFile() && !this.videoPlayer.isShortVideo) {
                this.skipBackwards();
            }
        }));
    }

    fit() {
        this.videoPlayer.setZoomToFit(true);
        this.videoPlayer.setPositionToCenter(true);
    }

    skipBackwards() {
        this.scrubRelative(-5);
    }

    skipForward() {
        this.scrubRelative(5);
    }

    canSkipForward() {
        return this.videoPlayer.currentTime !== this.videoPlayer.durationTime;
    }

    scrubRelative(delta) {
        const targetTime = Math.max(0, Math.min(this.videoPlayer.durationTime, this.videoPlayer.currentTime + delta));
        this.scrub(targetTime);
    }

    /**
     *
     */
    loadVideoPreview() {
        this.$$.sdk.files
            .thumbnail(this.proof.fileId)
            .then((preview) => {
                this.preview = URL.createObjectURL(preview);
                calculateImageDimensions(this.preview, (err, dimensions) => {
                    if ( ! err) {
                        this.$$.$scope.$apply(() => {
                            this.previewWidth = dimensions.width;
                            this.previewHeight = dimensions.height;
                        });
                    }
                });
            })
            .catch((err) => {
                if (!this.proof.postmanPat) {
                    this.proof.postmanPat = true;
                    this.$$.backendService.fetch('proof.report-issue-loading', {proofId: this.proof.id})
                }
                return window.generalfunctions_sleep(2000)
                    .then(() => this.loadVideoPreview());
            })
            .finally(() => {
                proof.postmanPat = false;
            });
    }

    /**
     * Whether a pin is currently visible.
     *
     * @param {sdk.Pin} pin
     * @param {Number} [currentTime]
     * @returns {Boolean}
     */
    isPinVisible (pin, currentTime = this.videoPlayer.currentTime) {
        const start = pin.time;
        const end = start + (pin.duration === 0.5 ? 0 : pin.duration);
        const isPositive = pin.duration > 0;

        return (
            (currentTime >= (isPositive ? start : end) - .150) && 
            (currentTime <= (isPositive ? end : start) + .150)
        );
    }

    /**
     * When the selection of a comment updates.
     *
     * @param {PPProofComment} previous
     * @param {PPProofComment} current
     */
    selectionUpdate (previous, current) {
        super.selectionUpdate(previous, current);

        if (current && !this._skipScrub) {
            // Scrub to the comment's position on the video
            this.scrubToComment(current);
        }
    }

    stampTimeAndDuration(pin) {
        pin.time = this.videoPlayer.currentTime;
        pin.duration = this.flags.commentDuration;
    }

    startCreateComment (data) {
        super.startCreateComment(data, undefined, (pins) => {
            if (this.interactionMode !== 'general') {
                this.stampTimeAndDuration(pins[pins.length - 1]);
            }
        });
        this.videoPlayer.pause();
    }

    updateTemporaryPin(data, pinIndex) {
        const { time, duration } = this.temporaryComment.pins[pinIndex];
        const result = super.updateTemporaryPin(data, pinIndex, (pins) => {
            const pin = pins[pinIndex];
            // Re-instate the time & duration from when the pin was originally left
            pin.time = time;
            pin.duration = duration;
        });
        return result;
    }

    scrubToComment (comment) {
        if (comment.pins.length) {
            this.scrubToPin(comment.pins[0]);
        } else if (comment.metadata && typeof comment.metadata.mediaTime !== 'undefined') {
            this.scrubToPin(comment.metadata.mediaTime);
        }
    }

    scrubToPin(pin) {
        this.scrub(pin.duration < 0 ? pin.time + pin.duration : pin.time);
        this.pause();
    }

    scrub(time) {
        this.videoPlayer.scrub(time);
    }

    pause() {
        this.videoPlayer.pause();
    }

    scrubToFrame(delta) {
        this.videoPlayer.scrubFrames(delta);
    }

    updateVideoPlayerPosition(position, commentPaneWidth) {
        super.updateCurrentCanvasPosition({
            top: position.y,
            left: position.x,
            width: position.width,
            height: position.height,
            zoomLevel: this.videoPlayer.getZoom(),
            angle: 0,
            isRotating: false,
        });
    }

    updatePin(comment, data, pinIndex) {
        const { time, duration } = comment.pins[pinIndex];
        return super.updatePin(comment, data, pinIndex, (pin) => {
            pin.time = time;
            pin.duration = duration;
        });
    }

    /**
     * Update comment with a new duration value
     *
     * @param {Number} commentIndex
     * @param {Number} newDuration
     * @param {number} pinIndex
     */
    updateCommentDuration (commentIndex, newDuration, pinIndex) {
        const comment = this.proof.pages[0].comments[commentIndex];
        const pin = comment.pins[pinIndex];
        const maxDuration = this.videoPlayer.durationTime - pin.time;
        pin.duration = newDuration > maxDuration ? maxDuration : newDuration;
        super.updateComment(comment);
    }

    updatePinById(commentId, pinIndex, updatedPin) {
        const comment = this.proof.pages[0].comments.find(comment => comment.id === commentId);
        const pin = comment.pins[pinIndex];
        if (pin.time !== updatedPin.time || pin.duration !== updatedPin.duration) {
            pin.time = updatedPin.time;
            pin.duration = updatedPin.duration;
            super.updateComment(comment);
        }
    }

    selectCommentById(commentId, scroll) {
        if (commentId === null) {
            this.comments.unselectComment();
        } else {
            const comment = this.proof.pages[0].comments.find(comment => comment.id === commentId);
            this.selectComment(comment, scroll, true);
        }
    }

    selectComment(comment, scroll, skipScrub) {
        if (skipScrub) {
            this._skipScrub = true;
        }
        super.selectComment(comment, scroll).then(() => {
            if (skipScrub) {
                this._skipScrub = false;
            }
        });
        this.flashComment(comment);
    }

    flashComment(comment) {
        if (this.isGeneralComment(comment)) {
            this.flashingGeneralComment = null;
            this.$$.$timeout(() => this.flashingGeneralComment = comment);
        }
    }

    toggleCollectionView() {
        super.toggleCollectionView();
        if (this.videoPlayer) {
            this.videoPlayer.pause();
        }
    }

    /**
     * Toggles focus mode of video proof page
     */
    toggleFocusMode = () => {
        if (this.isSmallScreen && !this.focusMode) return false;
        this.focusMode = !this.focusMode;
        this.toggleFocusModeInHeader(this.focusMode);
        if (this.focusMode) {
            const { width, height } = document.documentElement.getBoundingClientRect();
            const focusZoomLevel = Math.min((width / this.videoPlayer.width), ((height - 40) / this.videoPlayer.height));
            this.videoPlayer.zoomTo(Math.floor(focusZoomLevel * 100));
            this.movePlayerToCenter();
        } else {
            setTimeout(() => this.fit());
        }
    }
}

app
    .controller('VideoProofController', VideoProofController);
