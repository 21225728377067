/* Copyright (C) 2024 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

const VIDEOJS_VERSION = '8.10.0';
const VIDEOJS_PREFIX = `https://static-assets.pageproof.com/npm/video.js@${VIDEOJS_VERSION}/dist/`;
const VIDEOJS_SCRIPT_URL = VIDEOJS_PREFIX + 'video.min.js';
const VIDEOJS_STYLE_URL = VIDEOJS_PREFIX + 'video-js.min.css';

const VIDEO_CONTROLS_HEIGHT = 80;
const HEADER_HEIGHT = 100;
const FOOTER_HEIGHT = 101;
const HEADER_HEIGHT_SMALL_SCREEN = 67;
const FOOTER_HEIGHT_SMALL_SCREEN = 66;
const MIN_VIDEO_CONTROL_WIDTH = 705;
const MIN_VIDEO_CONTROL_WIDTH_SMALL_SCREEN = 510; // approx 390px for simple timecodes

const VOLUME_LOCAL_STORAGE_KEY = 'pageproof.app.user.video-player-volume';
const MUTED_LOCAL_STORAGE_KEY = 'pageproof.app.user.video-player-muted';

const supportsMSE = 'MediaSource' in window;
const amsProxyUrl = new URL('amp-proxy/manifest/first', env('api_url')).href;
const migratedVideoStreamingHostname = env('migrated_video_streaming_hostname');

function getProxyStreamingUrl(streamingUrl, accessToken) {
    if (streamingUrl.includes('streaming.mediaservices.windows.net')) {
        const url = new URL(amsProxyUrl);
        url.searchParams.set('playbackUrl', streamingUrl);
        url.searchParams.set('webtoken', accessToken);
        return url.href;
    }

    const url = new URL(streamingUrl);
    url.searchParams.set('token', accessToken);
    url.searchParams.set('rms_token_proxy', 'true');
    return url.href;
}

// Duplicated in /filters/videoTime.es6.js
function generateSMPTE(timeInSeconds, frameRate) {
    const totalFrames = Math.floor(timeInSeconds * frameRate);
    let droppedFramesPerMinute = 0;
    let frameNumber = totalFrames;
    
    // Calculate drop-frame adjustment (2 frames dropped every minute except every 10th minute)
    // See http://www.andrewduncan.net/timecodes/ for a good explanation of drop-frame
    const isDropFrame = frameRate === 29.97 || frameRate === 59.94;
    if (isDropFrame) {
        droppedFramesPerMinute = Math.round(frameRate * (2 / 30));

        // Calculations drop frames in 10 minute blocks, drop frames don't occur on minutes divisible by 10
        const framesPerBlock = Math.round(frameRate * 60 * 10);
        const dropFramesPerBlock = droppedFramesPerMinute * 9;
        const numberOfBlocks = Math.floor(totalFrames / framesPerBlock);
        const dropFramesInBlocks = dropFramesPerBlock * numberOfBlocks;
        frameNumber += dropFramesInBlocks;

        // Calculate drop frames in the remaining frames (after the last whole 10 minute block)
        const timeCodesPerMinute = (Math.round(frameRate) * 60) - droppedFramesPerMinute;
        const totalRemainingFrames = totalFrames % framesPerBlock;
        const totalRemainingMinutes = Math.floor((totalRemainingFrames - droppedFramesPerMinute) / timeCodesPerMinute)
        // Only add drop frames if there is more than 1 minute remaining
        if (totalRemainingFrames > timeCodesPerMinute) {
            frameNumber += droppedFramesPerMinute * totalRemainingMinutes;
        }
    }

    const roundedFrameRate = Math.round(frameRate);
    const totalSeconds = Math.floor(frameNumber / roundedFrameRate);
    const totalMinutes = Math.floor(totalSeconds / 60);
    const totalHours = Math.floor(totalMinutes / 60);

    const remainingFrames = frameNumber % roundedFrameRate;
    const remainingSeconds = totalSeconds % 60;
    const remainingMinutes = totalMinutes % 60;
    const remainingHours = totalHours % 24;

    const formattedHours = remainingHours.toString().padStart(2, '0');
    const formattedMinutes = remainingMinutes.toString().padStart(2, '0');
    const formattedSeconds = remainingSeconds.toString().padStart(2, '0');
    const formattedFrames = remainingFrames.toString().padStart(2, '0');

    const separator = isDropFrame ? ';' : ':';

    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}${separator}${formattedFrames}`;
}

/**
 * Calculate the visible width and visible mid-point of the video 
 * player within the container and returns the rounded values.
 * 
 * Values are rounded to improve performance and prevent streaks being left behind
 * when the player is scrolled below the controls at the bottom of the screen.
 */
function getVisibleVideoMeasurements(videoPlayerBounds, containerBounds) {
    const isVideoOffLeft = videoPlayerBounds.left < containerBounds.left;
    const isVideoOffRight = videoPlayerBounds.right > containerBounds.right;

    let visibleVideoWidth = videoPlayerBounds.width;
    let visibleVideoMidPoint = videoPlayerBounds.left + videoPlayerBounds.width / 2;
    if (isVideoOffLeft && isVideoOffRight) {
        visibleVideoWidth = containerBounds.width;
        visibleVideoMidPoint = containerBounds.left + containerBounds.width / 2;
    } else if (isVideoOffLeft) {
        visibleVideoWidth = videoPlayerBounds.width - (containerBounds.left - videoPlayerBounds.left);
        visibleVideoMidPoint = containerBounds.left + visibleVideoWidth / 2
    } else if (isVideoOffRight) {
        visibleVideoWidth = videoPlayerBounds.width - (videoPlayerBounds.right - containerBounds.right);
        visibleVideoMidPoint = videoPlayerBounds.left + visibleVideoWidth / 2;
    }

    return {
        visibleVideoWidth: Math.round(visibleVideoWidth),
        visibleVideoMidPoint: Math.round(visibleVideoMidPoint),
    };
};

class VideoPlayerController {
    $scope; // angular.Scope
    streamingUrl; // string
    accessToken; // string
    dimensions; // { width: number, height: number }
    loop; // boolean
    autoplay; // boolean
    fullWidth; // boolean
    syncPositionWith; // VideoPlayerController['api']
    initialQuality; // 'auto' (default) | 'highest'
    disableVolume; // boolean | undefined
    disableQuality; // boolean | undefined
    disableFrames; // boolean | undefined
    comments; // Comment[]
    selectedCommentId = null; // string | null
    whenPositionUpdated; // ({ position: { x: number, y: number } }) => void
    whenCommentSelected; // ({ commentId: string | null, pinIndex: number }) => void
    whenPinUpdated; // ({ commentId: string, pinIndex: number, pin: unknown }) => void
    getPinColor; // (comment: PPComment) => string (defined in BaseProofController)
    canUpdateComment; // (comment: PPComment) => boolean (defined in GenericProofController)
    containerElement; // HTMLElement
    overlayElement; // HTMLElement
    overlayInnerElement; // HTMLElement
    videoElement; // HTMLElement
    coverElement; // HTMLElement
    videoPlayerControls; // HTMLElement
    headerHeight; // number
    footerHeight; // number
    zoom = 1; // number
    isZoomToFit = true; // boolean
    position = { x: 0, y: 0 }; // { x: number, y: number }
    cleanup = []; // (() => void)[]
    isSmallScreen; // boolean
    isFocusMode = false; // boolean
    framesPerSecond = null; // number (from Proof's additional metadata)
    proofId; // string

    api = {
        proofId: this.proofId,
        isReady: false,
        isMuted: false,
        isPlaying: false,
        isFullscreen: false,
        isLooping: false,
        currentTime: -1,
        currentPresentationTime: '',
        durationTime: -1,
        durationPresentationTime: '',
        buffer: [],
        qualities: [],
        preferredQualityId: null,
        playbackQualityId: null,
        playbackRate: -1,
        volume: -1,
        comments: [],
        selectedCommentId: null,
        disableVolume: false,
        disableQuality: false,
        disableFrames: false,
        getPresentationTime: (seconds) => videojs.time.formatTime(seconds, this.api.durationTime),
        getNerdPresentationTime: (seconds) => generateSMPTE(seconds, this.framesPerSecond || 30),
        getCurrentTime: () => this.vjs.currentTime(),
        getZoom: () => this.getZoom(),
        setZoom: (zoom, isAnimated) => this.setZoom(zoom, isAnimated),
        setZoomToFit: (isAnimated) => this.setZoomToFit(isAnimated),
        setZoomToFill: (isAnimated) => this.setZoomToFill(isAnimated),
        setPositionToCenter: (isAnimated) => this.setPositionToCenter(isAnimated),
        getPosition: () => Object.assign({}, this.position),
        setPosition: (position, isAnimated) => this.setPosition(position, isAnimated),
        play: () => this.vjs.play(),
        pause: () => this.vjs.pause(),
        toggle: () => this.vjs.paused() ? this.vjs.play() : this.vjs.pause(),
        scrub: (seconds, inProgress) => this.scrub(seconds, inProgress),
        scrubSeconds: (secondsDelta, inProgress) => this.scrub(this.api.getCurrentTime() + secondsDelta, inProgress),
        scrubFrames: (framesDelta, inProgress) => {
            this.scrub(this.api.getCurrentTime() + (framesDelta / (this.framesPerSecond || 30)), inProgress);
            this.vjs.pause();
        },
        scrubEnd: () => this.vjs.scrubbing(false),
        on: (...args) => this.on(...args),
        toggleFullscreen: () => this.toggleFullscreen(),
        setPreferredQuality: (qualityId) => this.setPreferredQuality(qualityId),
        setPlaybackRate: (rate) => this.vjs.playbackRate(rate),
        setVolume: (volume) => this.vjs.volume(volume),
        setMuted: (isMuted) => this.setMuted(isMuted),
        setLooping: (isLooping) => this.setLooping(isLooping),
        selectComment: (commentId, pinIndex) => this.whenCommentSelected({ commentId, pinIndex }),
        updatePin: (commentId, pinIndex, pin) => this.whenPinUpdated({ commentId, pinIndex, pin }),
        getPinColor: (comment) => this.getPinColor(comment),
        canUpdateComment: (comment) => this.canUpdateComment(comment),
        unsafe: {
            api: () => this.api,
            syncPosition: (position) => {
                this.isZoomToFit = false;
                this.setPosition(position, false);
            },
        },
    };

    constructor($scope) {
        this.$scope = $scope;
    }

    ngApply(fn, args = []) {
        if (this.$scope.$$phase) {
            fn(...args);
        } else {
            this.$scope.$apply(() => fn(...args));
        }
    }

    on(event, fn) {
        const handler = () => this.ngApply(fn);
        const vjs = this.vjs; // keep the original vjs instance
        vjs.on(event, handler);
        return () => vjs.off(event, handler);
    }

    init() {
        const initialVolumeString = localStorage.getItem(VOLUME_LOCAL_STORAGE_KEY);
        const initialVolume = typeof initialVolumeString === 'string' ? +initialVolumeString : 1;

        const initialMutedString = localStorage.getItem(MUTED_LOCAL_STORAGE_KEY);
        const initialMuted = initialMutedString === 'true';

        //TODO: Use the browser service to detect if the user is on an iOS device
        const userAgent = window.navigator.userAgent.toLowerCase();
        if(userAgent.includes("iphone") || userAgent.includes("ipad")) {
            this.autoplay = true;
        }
        
        this.vjs = window.videojs(this.videoElement, {
            html5: {
                vhs: {
                    bandwidth: 100000000000000,
                    cacheEncryptionKeys: true,
                    overrideNative: true,
                    limitRenditionByPlayerDimensions: false,
                },
                nativeAudioTracks: false,
                nativeVideoTracks: false,
                nativeControlsForTouch: false,
            },
            controls: false,
            controlBar: {
                children: [],
            },
            bigPlayButton: false,
            titleBar: false,
            loadingSpinner: false,
            errorDisplay: false,
            textTrackSettings: false,
            textTrackDisplay: false,
            poster: false,
            autoplay: !!this.autoplay,
            muted: initialMuted,
            preload: 'auto',
        });

        let url = this.streamingUrl;

        if (!migratedVideoStreamingHostname) {
            console.error('migrated_video_streaming_hostname is not set');
        } else if (this.streamingUrl.includes('streaming.mediaservices.windows.net')) {
            const updatedUrl = new URL(this.streamingUrl);
            updatedUrl.hostname = migratedVideoStreamingHostname;
            updatedUrl.pathname = updatedUrl.pathname.replace('m3u8-aapl', 'm3u8-cmaf');
            url = updatedUrl.href;
        }
    
        if (!supportsMSE) {
            url = getProxyStreamingUrl(url, this.accessToken);
        }

        this.vjs.src({
            src: url,
            type: 'application/x-mpegURL',
        });

        const tech = this.vjs.tech({ IWillNotUseThisInPlugins: true });
        this.tech = tech;

        if (supportsMSE) {
            this.vjs.on('xhr-hooks-ready', () => {
                tech.vhs.xhr.onRequest((options) => {
                    options.beforeSend = (xhr) => {
                        if (xhr.url.includes('vod/keyservice') || xhr.url.includes('keydelivery')) {
                            xhr.setRequestHeader('Authorization', 'Bearer ' + this.accessToken);
                        }
                    };
                    return options;
                });
            });
        }

        this.on(['play', 'playing'], () => {
            this.api.isPlaying = true;
        });
        this.on(['pause', 'waiting'], () => {
            this.api.isPlaying = false;
        });
        this.on(['timeupdate', 'durationupdate', 'canplay'], () => {
            this.onTimeUpdate();
        });
        this.on('progress', () => {
            this.onProgress();
        });
        this.on('loadedmetadata', () => {
            this.setFallbackDimensionsFromHighestQuality();
            this.initQualities();
            this.api.isReady = true;
        });
        this.on('loadeddata', () => {
            this.initSizing();
            this.initOverlayInteractions();
            this.api.isReady = true;
        });
        this.on('volumechange', () => {
            this.api.volume = this.vjs.volume();
            localStorage.setItem(VOLUME_LOCAL_STORAGE_KEY, this.api.volume.toString());

            this.api.isMuted = this.vjs.muted();
            localStorage.setItem(MUTED_LOCAL_STORAGE_KEY, this.api.isMuted.toString());
        });
        this.on('ratechange', () => {
            this.api.playbackRate = this.vjs.playbackRate();
        });

        // TODO: cleanup
        window.addEventListener('fullscreenchange', this.onFullscreenChange);
        this.cleanup.push(() => window.removeEventListener('fullscreenchange', this.onFullscreenChange));

        this.api.isLooping = !!this.loop;
        tech.el().loop = this.api.isLooping;

        this.api.isMuted = this.vjs.muted();
        this.api.playbackRate = this.vjs.playbackRate();

        this.api.proofId = this.proofId;

        this.api.volume = initialVolume;
        this.vjs.volume(initialVolume);

        tech.el().playsInline = true;

        this.initOverlay();
        this.headerHeight = this.isSmallScreen ? HEADER_HEIGHT_SMALL_SCREEN : HEADER_HEIGHT;
        this.footerHeight = this.isSmallScreen ? FOOTER_HEIGHT_SMALL_SCREEN : FOOTER_HEIGHT;
    }

    setFallbackDimensionsFromHighestQuality() {
        if (
            this.dimensions &&
            this.dimensions.width !== null &&
            this.dimensions.height !== null &&
            this.dimensions.width !== 0 &&
            this.dimensions.height !== 0
        ) {
            return;
        }

        const qualityLevels = this.getVideoQualityLevels();

        const highestQuality = Array.from(qualityLevels)
            .sort((a, b) => b.height - a.height)[0];

        this.dimensions = {
            width: highestQuality.width,
            height: highestQuality.height,
        };
    }

    initOverlay() {
        this.setOverlayElementStyles();

        const resizeObserver = new ResizeObserver(() => {
            this.setVideoControlsStyles();
            this.setOverlayElementStyles();
        });
        resizeObserver.observe(this.overlayElement);
        this.cleanup.push(() => resizeObserver.disconnect());
    }

    setOverlayElementStyles() {
        if (!this.api.isFullscreen) {
            Object.assign(this.overlayInnerElement.style, {
                width: '',
                height: '',
            });
            return;
        }

        const zoom = Math.min(
            this.overlayElement.clientWidth / this.dimensions.width,
            this.overlayElement.clientHeight / this.dimensions.height
        );

        Object.assign(this.overlayInnerElement.style, {
            width: this.dimensions.width * zoom + 'px',
            height: this.dimensions.height * zoom + 'px',
        });
    }

    onFullscreenChange = () => {
        this.ngApply(() => {
            this.api.isFullscreen = document.fullscreenElement === this.coverElement;
            this.setOverlayElementStyles();
        });
        this.setVideoControlsStyles();
    }

    setLooping(isLooping) {
        this.tech.el().loop = isLooping;

        this.ngApply(() => {
            this.api.isLooping = isLooping;
        });
    }

    toggleFullscreen() {
        if (this.api.isFullscreen) {
            document.exitFullscreen();
        } else {
            this.coverElement.requestFullscreen();
        }
    }

    getVideoQualityLevels() {
        return Array.from(this.vjs.qualityLevels())
            .filter((level) => level.height != null);
    }

    initQualities() {
        const qualityLevels = this.getVideoQualityLevels();
        
        let qualityLevelsArray = Array.from(qualityLevels);

        this.api.qualities = qualityLevelsArray
            .sort((a, b) => b.height - a.height)
            .filter((level, index, levels) => {
                return levels.findIndex(l => l.height === level.height) === index;
            })
            .map(level => ({
                id: level.height,
                label: level.height + 'p',
                info: {
                    width: level.width,
                    height: level.height,
                    framesPerSecond: level.frameRate,
                },
            }));
            

        const initialQualityId = this.initialQuality === 'highest' && this.api.qualities.length > 0
            ? this.api.qualities[0].id
            : null;
        
        this.enableQuality(initialQualityId);
        this.api.preferredQualityId = initialQualityId;
    
        this.on('resize', () => {
            this.onVideoResize();
        });
        this.onVideoResize();

    }

    getPlaybackQualityLevel() {
        if (!this.tech.vhs) {
            return null; // iOS
        }
        const qualityLevels = this.getVideoQualityLevels();
        const playbackId = this.tech.vhs.playlists.media().id;
        // console.log({ qualityLevels, media: this.tech.vhs.playlists.media() });
        return Array.from(qualityLevels).find((level) => level.id === playbackId);
    }

    onVideoResize() {
        const playbackQualityLevel = this.getPlaybackQualityLevel();

        if (playbackQualityLevel) {
            this.api.playbackQualityId = playbackQualityLevel.height;
        } else {
            this.api.playbackQualityId = null;
        }
    }

    enableQuality(qualityId) {
        const qualityLevels = this.getVideoQualityLevels();

        Array.from(qualityLevels).forEach((level) => {
            if (qualityId === null) {
                level.enabled = true;
            } else {
                level.enabled = level.height === qualityId;
            }
        });
    }

    setPreferredQuality(qualityId) {
        this.enableQuality(qualityId);

        this.ngApply(() => {
            this.api.preferredQualityId = qualityId;
        });

        if (qualityId !== null) {
            this.clearBuffers();
        }
    }

    clearBuffers() {
        const sourceBuffers = this.tech.vhs.mediaSource.sourceBuffers;

        for (const sourceBuffer of sourceBuffers) {
            try {
                sourceBuffer.abort();
                sourceBuffer.remove(0, Infinity);
            } catch (err) {
                console.error(err);
            }
        }

        this.vjs.currentTime(this.vjs.currentTime());
    }

    scrub(seconds, inProgress = false) {
        if (inProgress && !this.vjs.scrubbing()) {
            this.vjs.scrubbing(true);
            this.vjs.pause();
        }

        this.vjs.currentTime(seconds);
        this.onTimeUpdate();
    }

    onTimeUpdate(
        currentTime = this.vjs.currentTime(),
        durationTime = this.vjs.duration()
    ) {
        this.api.currentTime = currentTime;
        this.api.currentPresentationTime = videojs.time.formatTime(currentTime, durationTime);

        if (durationTime !== this.api.durationTime) {
            this.api.durationTime = durationTime;
            this.api.durationPresentationTime = videojs.time.formatTime(durationTime);
        }
    }

    onProgress() {
        const buffered = this.vjs.buffered();
        const duration = this.vjs.duration();

        const buffer = [];
        for (let i = 0; i < buffered.length; i++) {
            const start = buffered.start(i);
            const end = buffered.end(i);

            if (start !== end) {
                buffer.push({
                    start,
                    end,
                    left: ((start / duration) * 100) + '%',
                    width: (((end - start) / duration) * 100) + '%',
                });
            }
        }
        this.api.buffer = buffer;
    }

    onElementEvent(element, event, handler) {
        element.addEventListener(event, handler);
        this.cleanup.push(() => element.removeEventListener(event, handler));
    }

    initOverlayInteractions() {
        let didJustPan = false;

        this.onElementEvent(this.overlayElement, 'click', () => {
            if (didJustPan) {
                didJustPan = false;
                return;
            }

            this.api.toggle();
        });

        this.onElementEvent(this.overlayElement, 'dblclick', () => {
            this.api.toggleFullscreen();
        });

        const didPan = () => {
            this.isZoomToFit = false;
            this.setMoveAnimation(false);
            this.setCoverElementStyles();

            if (this.syncPositionWith) {
                this.syncPositionWith.unsafe.syncPosition(this.position);
            }
        };

        this.onElementEvent(this.overlayElement, 'pointerdown', (event) => {
            event.preventDefault();

            if (this.api.isFullscreen || event.button !== 0) {
                return;
            }

            const startCursor = { x: event.pageX, y: event.pageY };
            const startPosition = { x: this.position.x, y: this.position.y };

            const onMouseMove = (event) => {
                this.position = {
                    x: startPosition.x + (event.pageX - startCursor.x),
                    y: startPosition.y + (event.pageY - startCursor.y),
                };

                didPan();
                didJustPan = true;
            };
            window.addEventListener('pointermove', onMouseMove);

            const cleanup = () => {
                window.removeEventListener('pointermove', onMouseMove);
                window.removeEventListener('pointerup', onMouseUp);
            };
            this.cleanup.push(cleanup);

            const onMouseUp = (event) => {
                event.stopPropagation();
                cleanup();
            };
            window.addEventListener('pointerup', onMouseUp);
        });

        this.onElementEvent(this.containerElement, 'wheel', (event) => {
            event.preventDefault();

            const deltaX = event.deltaX ? -event.deltaX : event.movementX;
            const deltaY = event.deltaY ? -event.deltaY : event.movementY;

            this.position = {
                x: this.position.x + deltaX,
                y: this.position.y + deltaY,
            };

            didPan();
        });
    }

    initSizing() {
        const resizeObserver = new ResizeObserver(() => {
            if (this.isZoomToFit) {
                this.setZoomToFit();
            }
            this.setMoveAnimation(false);
            this.setCoverElementStyles();
        });
        resizeObserver.observe(this.containerElement);
        this.cleanup.push(() => resizeObserver.disconnect());

        this.setZoomToFit();
        this.setPositionToCenter();
        this.setMoveAnimation(false);
        this.setCoverElementStyles();
    }

    setCoverElementStyles() {
        const width = this.dimensions.width * this.zoom;
        const height = this.dimensions.height * this.zoom;

        const topOffset = this.isFocusMode ? 0 : this.headerHeight;
        const bottomOffset = VIDEO_CONTROLS_HEIGHT / 2 + (this.isFocusMode ? 0 : this.footerHeight);
        const yOffset = topOffset - bottomOffset;

        Object.assign(this.coverElement.style, {
            width: width + 'px',
            height: height + VIDEO_CONTROLS_HEIGHT + 'px',
            top: `calc(50% - ${Math.round(height / 2)}px + ${Math.round(this.position.y + yOffset)}px)`,
            left: `calc(50% - ${Math.round(width / 2)}px + ${Math.round(this.position.x)}px)`,
            transform: 'none'
        });

        this.didPositionUpdate();
    }

    didPositionUpdate() {
        if (this.whenPositionUpdated) {
            const containerRect = this.containerElement.getBoundingClientRect();
            const coverRect = this.coverElement.getBoundingClientRect();

            this.whenPositionUpdated({
                position: {
                    x: coverRect.left - containerRect.left,
                    y: coverRect.top - containerRect.top,
                    width: coverRect.width,
                    height: coverRect.height + VIDEO_CONTROLS_HEIGHT,
                },
            });
        }
        this.setVideoControlsStyles();
    }

    // Only set the controls to be in the middle when the controls are larger?
    updateVideoControlStyles(styles) {
        Object.assign(this.videoPlayerControls.style, styles);
    }

    setVideoControlsStyles() {
        if (this.videoPlayerControls) {
            // Ignore the offset if we're in focus mode
            const Y_OFFSET = this.isFocusMode ? 0 : this.headerHeight;

            if (this.api.isFullscreen) {
                this.updateVideoControlStyles({
                    minWidth: Math.round(window.innerWidth) + 'px',
                    maxWidth: '',
                    left: '50%',
                    transform: 'translateX(-50%)',
                });
                return;
            }

            // containerBounds is reliable in both single and compare mode.
            const containerBounds = this.containerElement.getBoundingClientRect();
            const videoPlayerBounds = this.coverElement.getBoundingClientRect();
            const videoControlBounds = this.videoPlayerControls.getBoundingClientRect();

            const { visibleVideoWidth, visibleVideoMidPoint } = getVisibleVideoMeasurements(videoPlayerBounds, containerBounds);
            const isVideoOffLeft = videoPlayerBounds.left < containerBounds.left;
            const isVideoOffRight = videoPlayerBounds.right > containerBounds.right;
            const isControlsOffLeft = visibleVideoMidPoint - (videoControlBounds.width / 2) < containerBounds.left;
            const isControlsOffRight = visibleVideoMidPoint + (videoControlBounds.width / 2) > containerBounds.right;

            const minControlWidth = this.isSmallScreen ? MIN_VIDEO_CONTROL_WIDTH_SMALL_SCREEN : MIN_VIDEO_CONTROL_WIDTH;

            const style = {
                minWidth: Math.max(visibleVideoWidth, minControlWidth) + 'px',
                maxWidth: Math.round(containerBounds.width) + 'px',
                left: '',
            }

            if ((isVideoOffLeft && isVideoOffRight) || containerBounds.width < minControlWidth) {
                style.maxWidth = containerBounds.width + 'px';
                style.transform = `translateX(${Math.round(containerBounds.left - videoPlayerBounds.left)}px)`;
            } else if (isVideoOffLeft || isControlsOffLeft) {
                style.maxWidth = Math.max(visibleVideoWidth, minControlWidth) + 'px';
                style.transform = `translateX(${Math.round(containerBounds.left - videoPlayerBounds.left)}px)`;
            } else if (isVideoOffRight || isControlsOffRight) {
                const playerWidth = videoPlayerBounds.right - videoPlayerBounds.left;
                style.maxWidth = Math.max(visibleVideoWidth, minControlWidth) + 'px';
                style.transform = '';
                if (!isVideoOffRight) {
                    // Gap = the distance between the edge of the controls and the edge of the video player
                    const gapRight = containerBounds.right - videoPlayerBounds.right;
                    const gapLeft = (minControlWidth - playerWidth) / 2;
                    const offScreen = gapLeft - gapRight;
                    style.transform = `translateX(-${Math.round(offScreen + gapLeft)}px)`;
                } else if (visibleVideoWidth < minControlWidth) {
                    style.transform = `translateX(-${minControlWidth - visibleVideoWidth}px)`;
                }
            } else {
                style.left = '50%';
                style.transform = 'translateX(-50%)';
            }

            // Prevent the controls from going off the bottom of the screen
            // Using window.innerHeight instead of container height to stay consistent between compare and single mode
            const screenBottom = window.innerHeight - Y_OFFSET;
            const videoBottom = videoPlayerBounds.bottom;
            if (videoBottom > screenBottom) {
                style.transform = style.transform + `translateY(-${Math.round(videoBottom - screenBottom)}px)`;
            }

            this.updateVideoControlStyles(style);
        }
    }

    getContainerBounds() {
        const containerBounds = this.containerElement.getBoundingClientRect();
        const yPadding = VIDEO_CONTROLS_HEIGHT + (this.isFocusMode ? 0 : this.headerHeight + this.footerHeight);

        return {
            width: containerBounds.width - (!this.fullWidth && window.innerWidth > 750 ? 200 : 0),
            height: containerBounds.height - yPadding - (this.isSmallScreen ? this.headerHeight : 0),
        };
    }

    setZoomToFit(isAnimated) {
        const containerBounds = this.getContainerBounds();

        this.zoom = Math.min(1, Math.min(
            containerBounds.width / this.dimensions.width,
            containerBounds.height / this.dimensions.height
        ));
        this.isZoomToFit = true;

        this.setMoveAnimation(isAnimated);
        this.setCoverElementStyles();
    }

    setZoomToFill(isAnimated) {
        const containerBounds = this.getContainerBounds();

        this.zoom = Math.min(
            containerBounds.width / this.dimensions.width,
            containerBounds.height / this.dimensions.height
        );
        this.isZoomToFit = false;

        this.setMoveAnimation(isAnimated);
        this.setCoverElementStyles();
    }

    setPositionToCenter(isAnimated) {
        this.position = { x: 0, y: 0 };

        this.setMoveAnimation(isAnimated);
        this.setCoverElementStyles();
    }

    setPosition(position, isAnimated) {
        this.position = position;

        this.setMoveAnimation(isAnimated);
        this.setCoverElementStyles();
    }

    setMoveAnimation(isAnimated) {
        if (typeof isAnimated === 'undefined') {
            return;
        }

        if (isAnimated) {
            const animationDuration = 200;
            this.coverElement.style.transition = `${animationDuration}ms ease-in-out`;
            this.coverElement.style.transitionProperty = 'top, left, width, height';

            clearTimeout(this._moveAnimationTimeout);
            this._moveAnimationTimeout = setTimeout(() => this.setMoveAnimation(false), animationDuration);

            cancelAnimationFrame(this._moveAnimationRaf);
            const nextFrame = () => {
                this.didPositionUpdate();
                this._moveAnimationRaf = requestAnimationFrame(nextFrame);
            };
            this._moveAnimationRaf = requestAnimationFrame(nextFrame);
        } else {
            this.coverElement.style.transition = '';
            this.coverElement.style.transitionProperty = '';

            clearTimeout(this._moveAnimationTimeout);
            this._moveAnimationTimeout = null;

            cancelAnimationFrame(this._moveAnimationRaf);
            this._moveAnimationRaf = null;
        }
    }

    setZoom(zoom, isAnimated) {
        this.zoom = zoom;
        this.isZoomToFit = false;

        this.setMoveAnimation(isAnimated);
        this.setCoverElementStyles();
    }

    getZoom() {
        return this.zoom;
    }

    setMuted(isMuted) {
        this.vjs.muted(isMuted);
        this.api.isMuted = !!isMuted;
    }

    didCommentsUpdate() {
        this.api.comments = this.comments;
        this.api.selectedCommentId = this.selectedCommentId || null;
    }

    sourceDidUpdate() {
        this.destroy();

        clearTimeout(this._reinitTimeout);
        this._reinitTimeout = setTimeout(() => {
            this.$scope.$apply(() => {
                this.init();
            });
        });
    }

    destroy() {
        this.cleanup.forEach((fn) => {
            try {
                fn();
            } catch (err) {
                console.error(err);
            }
        });
        this.cleanup.length = 0;

        if (this.vjs) {
            this.vjs.dispose();
            this.vjs = null;

            // Because VideoJS removes the video element, we need to add it back in in case we're reinitializing
            const newVideoElement = document.createElement('video');
            newVideoElement.className = 'app__video-player__element';
            this.videoElement = newVideoElement;
            this.coverElement.prepend(this.videoElement);
        }
    }
}

function VideoPlayerDirective ($q, directiveHelper, utilService) {
    return {
        restrict: 'E',
        require: ['videoPlayer'],
        template: `
            <div class="app__video-player">
                <div class="app__video-player__container">
                    <div class="app__video-player__cover" ng-class="{ 'app__video-player__cover--is-fullscreen': videoPlayerCtrl.api.isFullscreen }">
                        <video class="app__video-player__element">
                            <div>
                                <track kind="captions" src="" srclang="en" label="English" default>
                            </div>
                        </video>
                        <div class="app__video-player__overlay">
                            <div class="app__video-player__overlay__inner" ng-transclude></div>
                        </div>
                        <div class="app__video-player__controls">
                            <react-component name="VideoPlayer" props="videoPlayerCtrl.api" ng-if="videoPlayerCtrl.api.isReady"></react-component>
                        </div>
                        <div class="video-player-fullscreen-tooltip-mount"></div>
                    </div>
                </div>
            </div>
        `,
        controller: 'VideoPlayerController',
        controllerAs: 'videoPlayerCtrl',
        transclude: true,
        scope: true,

        link (scope, element, attr, [videoPlayerCtrl]) {
            const wait = [];

            videoPlayerCtrl.containerElement = element.find('.app__video-player__container')[0];
            videoPlayerCtrl.overlayElement = element.find('.app__video-player__overlay')[0];
            videoPlayerCtrl.overlayInnerElement = element.find('.app__video-player__overlay__inner')[0];
            videoPlayerCtrl.videoElement = element.find('.app__video-player__element')[0];
            videoPlayerCtrl.coverElement = element.find('.app__video-player__cover')[0];
            videoPlayerCtrl.videoPlayerControls = element.find('.app__video-player__controls')[0];

            ['streamingUrl', 'accessToken'].forEach((key) => {
                let hasInitial = false;
                wait.push($q((resolve) => {
                    directiveHelper.oneWayBinding(scope, attr, key, videoPlayerCtrl, key, false, (value) => {
                        if (value) {
                            if (hasInitial) {
                                videoPlayerCtrl.sourceDidUpdate();
                            } else {
                                hasInitial = true;
                                resolve();
                            }
                        }
                    });
                }));
            });

            if ('autoplay' in attr) {
                directiveHelper.oneWayBinding(scope, attr, 'autoplay', videoPlayerCtrl, 'autoplay');
            }

            if ('loop' in attr) {
                directiveHelper.oneWayBinding(scope, attr, 'loop', videoPlayerCtrl, 'loop');
            }

            if ('fullWidth' in attr) {
                directiveHelper.oneWayBinding(scope, attr, 'fullWidth', videoPlayerCtrl, 'fullWidth');
            }

            if ('comments' in attr) {
                directiveHelper.oneWayBinding(scope, attr, 'comments', videoPlayerCtrl, 'comments', true, () => videoPlayerCtrl.didCommentsUpdate());
            }

            if ('selectedCommentId' in attr) {
                directiveHelper.oneWayBinding(scope, attr, 'selectedCommentId', videoPlayerCtrl, 'selectedCommentId', false, () => videoPlayerCtrl.didCommentsUpdate());
            }

            if ('dimensions' in attr) {
                directiveHelper.oneWayBinding(scope, attr, 'dimensions', videoPlayerCtrl, 'dimensions');
            }

            if ('bind' in attr) {
                directiveHelper.controllerBinding(scope, attr, 'bind', videoPlayerCtrl.api);
            }

            if ('whenPositionUpdated' in attr) {
                directiveHelper.callbackBinding(scope, attr, 'whenPositionUpdated', videoPlayerCtrl, 'whenPositionUpdated');
            }

            if ('whenCommentSelected' in attr) {
                directiveHelper.callbackBinding(scope, attr, 'whenCommentSelected', videoPlayerCtrl, 'whenCommentSelected');
            }

            if ('whenPinUpdated' in attr) {
                directiveHelper.callbackBinding(scope, attr, 'whenPinUpdated', videoPlayerCtrl, 'whenPinUpdated');
            }

            if ('isSmallScreen' in attr) {
                directiveHelper.oneWayBinding(scope, attr, 'isSmallScreen', videoPlayerCtrl, 'isSmallScreen');
            }

            if ('isFocusMode' in attr) {
                directiveHelper.oneWayBinding(scope, attr, 'isFocusMode', videoPlayerCtrl, 'isFocusMode');
            }

            if ('getPinColor' in attr) {
                directiveHelper.oneWayBinding(scope, attr, 'getPinColor', videoPlayerCtrl, 'getPinColor');
            }

            if ('canUpdateComment' in attr) {
                directiveHelper.oneWayBinding(scope, attr, 'canUpdateComment', videoPlayerCtrl, 'canUpdateComment');
            }

            if ('syncPositionWith' in attr) {
                directiveHelper.oneWayBinding(scope, attr, 'syncPositionWith', videoPlayerCtrl, 'syncPositionWith');
            }

            if ('initialQuality' in attr) {
                directiveHelper.oneWayBinding(scope, attr, 'initialQuality', videoPlayerCtrl, 'initialQuality');
            }

            if ('proofId' in attr) {
                directiveHelper.oneWayBinding(scope, attr, 'proofId', videoPlayerCtrl, 'proofId');
            }

            if ('isLinked' in attr) {
                directiveHelper.oneWayBinding(scope, attr, 'isLinked', videoPlayerCtrl, 'isLinked', false, () => {
                    setTimeout(() => videoPlayerCtrl.setVideoControlsStyles());
                });
            }

            if ('disableVolume' in attr) {
                directiveHelper.oneWayBinding(scope, attr, 'disableVolume', videoPlayerCtrl, 'disableVolume', false, (disableVolume) => {
                    videoPlayerCtrl.api.disableVolume = !!disableVolume;
                });
            }

            if ('disableQuality' in attr) {
                directiveHelper.oneWayBinding(scope, attr, 'disableQuality', videoPlayerCtrl, 'disableQuality', false, (disableQuality) => {
                    videoPlayerCtrl.api.disableQuality = !!disableQuality;
                });
            }

            if ('disableFrames' in attr) {
                directiveHelper.oneWayBinding(scope, attr, 'disableFrames', videoPlayerCtrl, 'disableFrames', false, (disableFrames) => {
                    videoPlayerCtrl.api.disableFrames = !!disableFrames;
                });
            }

            if ('framesPerSecond' in attr) {
                directiveHelper.oneWayBinding(scope, attr, 'framesPerSecond', videoPlayerCtrl, 'framesPerSecond');
            }

            utilService.loadStyle(VIDEOJS_STYLE_URL);
            wait.push(utilService.loadScript(VIDEOJS_SCRIPT_URL));

            $q.all(wait).then(() => videoPlayerCtrl.init());

            scope.$on('$destroy', () => videoPlayerCtrl.destroy());
        }
    };
}

app
    .controller('VideoPlayerController', VideoPlayerController)
    .directive('videoPlayer', VideoPlayerDirective);
